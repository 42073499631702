<template>
  <div class="sidebar-panel">
    <div class="button-bar z-1">
      <Button v-if="hasVisibleSidebar" class="selector p-button-text" :icon="sidebarControlIcon"
        @click="sidebarControl_clickHandler" />
      <div v-if="!maximizedId">
        <Button v-if="zoomEnabledForAnySite"
          :class="`selector p-button-text ${callsInProgress.length ? 'active-call' : ''}`" icon="pi pi-phone"
          @click="zoom_clickHandler">
          <i v-if="callsInProgress.length" v-badge.success="" class="pi pi-phone p-overlay-badge active-call-button" />
        </Button>
        <Button v-if="usFeaturesEnabledForAnySite" class="selector p-button-text p-button-lg" icon="pi pi-comments"
          @click="sms_clickHandler">
          <i v-if="error" v-badge.danger="'!'" class="pi pi-comments p-overlay-badge" />
          <i v-else-if="unreadChannelsCount" v-badge.success="unreadChannelsCount"
            class="pi pi-comments p-overlay-badge" />
        </Button>
        <Button v-if="isCareCalendarEnabled" class="selector p-button-text p-button-lg" icon="pi pi-calendar"
          @click="toggleCalendarSidebar">
        </Button>
      </div>
    </div>
    <component v-bind:is="getComponent(sidebar.tag)" v-for="sidebar in activeSidebars" :id="sidebar.id"
      :key="sidebar.id" :payload="sidebar.payload" />
  </div>
</template>

<script>
import { SIDEBAR_TAGS, SIDEBAR_COMPONENTS } from "@/components/layout/sidebar/SidebarConstants";
import { createNamespacedHelpers } from 'vuex';

// Include imports for all sidebars that are called out in SIDEBAR_TAGS
import CommunicationLogSidebar from "@/components/layout/sidebar/sidebar-panels/CommunicationLogSidebar";
import ZoomSidebar from "@/components/layout/sidebar/sidebar-panels/ZoomSidebar";
import ZoomTranscriptSidebar from "@/components/layout/sidebar/sidebar-panels/ZoomTranscriptSidebar";
import SmsSidebar from "@/components/layout/sidebar/sidebar-panels/sms/SmsSidebar";
import CalendarSidebar from "@/components/layout/sidebar/sidebar-panels/CalendarSidebar";

const portalVuex = createNamespacedHelpers('portal')
const sidebarVuex = createNamespacedHelpers('sidebar')
const zoomVuex = createNamespacedHelpers('zoom')
const messagingVuex = createNamespacedHelpers('messaging')

export default {
  data () {
    return {
      smsAutoRefreshInterval: null,
    };
  },
  components: {
    CommunicationLogSidebar,
    ZoomSidebar,
    ZoomTranscriptSidebar,
    SmsSidebar,
    CalendarSidebar
  },
  mounted () {
    if (this.zoomEnabledForAnySite) {
      this.addSidebar({ tag: SIDEBAR_TAGS.ZOOM, payload: { hidden: true } });
    }
    this.fetchChannels();
    this.initMessagingModule();
  },
  destroyed () {
    this.teardownMessagingModule();
  },
  computed: {
    ...portalVuex.mapGetters(['hasAnySiteWithFeatureValue', 'isCareCalendarEnabled']),
    ...zoomVuex.mapState(['callsInProgress']),
    ...sidebarVuex.mapState(['expanded', 'activeSidebars', 'maximizedId']),
    ...sidebarVuex.mapGetters(['isSidebarOpen']),
    ...messagingVuex.mapState(['error']),
    ...messagingVuex.mapGetters(['unreadChannelsCount']),
    zoomEnabledForAnySite () {
      return this.hasAnySiteWithFeatureValue('zoom_smart_integration_feature', 'true');
    },
    usFeaturesEnabledForAnySite () {
      return this.hasAnySiteWithFeatureValue('portal_us_features', 'true');
    },
    sidebarControlIcon () {
      return this.expanded || this.maximizedId ? "pi pi-angle-right" : "pi pi-angle-left";
    },
    hasVisibleSidebar () {
      return this.activeSidebars.filter(s => !s.payload?.hidden).length;
    },
  },
  methods: {
    ...sidebarVuex.mapActions(['expandSidebar', 'collapseSidebar', 'addSidebar', 'minimizeSidebar']),
    ...messagingVuex.mapActions(['fetchChannels', 'initMessagingModule', 'teardownMessagingModule']),
    zoom_clickHandler () {
      this.expandSidebar();
      this.addSidebar({ tag: SIDEBAR_TAGS.ZOOM });
    },
    sms_clickHandler () {
      this.expandSidebar();
      this.addSidebar({ tag: SIDEBAR_TAGS.SMS });
    },
    toggleCalendarSidebar () {
      if (this.isSidebarOpen(SIDEBAR_TAGS.CALENDAR)) {
        this.minimizeSidebar();
      } else {
        this.expandSidebar();
        this.addSidebar({ tag: SIDEBAR_TAGS.CALENDAR });
      }
    },
    getComponent (tag) {
      return SIDEBAR_COMPONENTS[tag] || null;
    },
    sidebarControl_clickHandler () {
      if (this.maximizedId) {
        this.minimizeSidebar();
      } else if (this.expanded) {
        this.collapseSidebar();
      } else {
        this.expandSidebar();
      }
    },
    setupSMSAutoRefresh () {
      this.smsAutoRefreshInterval = setInterval(() => {
        this.fetchChannels();
      }, 20000);
    },
    tearDownAutoRefresh () {
      clearInterval(this.smsAutoRefreshInterval);
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .sidebar-panel {
    display: none !important;
  }
}

::v-deep .pi {
  font-size: 18px;
}

.sidebar-panel {
  position: fixed;
  top: 48px;
  right: 0;
  background-color: white;
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 48px);
  max-height: calc(100vh - 48px);
  padding: 0;
  height: 100%;
  z-index: 199;

  .button-bar {
    height: calc(100vh - 48px);
    max-width: 52px;
    min-width: 52px;
    padding-top: 12px;
    box-shadow: -4px 0px 14px 2px #00000014;

    .selector {
      .pi {
        font-size: 18px;
      }

      width: 52px;
      height: 52px;
      line-height: 52px;
      text-align: center;
      overflow: hidden;
      cursor: pointer;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;

      &.active-call {
        color: #05A660;
      }

      .active-call-button {
        ::v-deep .p-badge {
          height: 0.75rem;
          min-width: 0.75rem;
        }
      }
    }
  }
}
</style>
