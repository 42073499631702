export const state = () => ({
  callsInProgress: [],
  callAttemptInitiated: false,
  storedProfileForPlacedCall: null,
  error: '',
});

export const mutations = {
  SET_ERROR (state, error) {
    state.error = error;
  },
  SET_CALL_ATTEMPT_INITIATED (state, callAttempt) {
    state.callAttemptInitiated = callAttempt;
  },
  ADD_CALL_IN_PROGRESS (state, callInProgress) {
    state.callsInProgress = [...state.callsInProgress, callInProgress];
  },
  UPDATE_CALLS_IN_PROGRESS (state, updatedCallsInProgress) {
    state.callsInProgress = updatedCallsInProgress;
  },
  REMOVE_CALL_IN_PROGRESS (state, callId) {
    state.callsInProgress = state.callsInProgress.filter(call => call.callId !== callId);
  },
  UPDATE_STORED_PROFILE_FOR_PLACED_CALL (state, profile) {
    state.storedProfileForPlacedCall = profile;
  },
};

export const actions = {
  initializeZoomPhone (_, configParams) {
    setTimeout(() => {
      document.querySelector('iframe#zoom-embeddable-phone-iframe').contentWindow.postMessage({
        type: 'zp-init-config',
        data: configParams || {},
      }, 'https://applications.zoom.us');
    },1000);
  },
  startCall ({ commit }, phoneNumber) {
    commit('SET_ERROR', '');
    commit('SET_CALL_ATTEMPT_INITIATED', true);
    try {
      if (!phoneNumber) {
        throw new Error('Cannot start call without phone number');
      }
      document.querySelector('iframe#zoom-embeddable-phone-iframe').contentWindow.postMessage({
        type: 'zp-make-call',
        data: {
          number: phoneNumber,
          autoDial: true, 
        }
      }, 'https://applications.zoom.us');
    }
    catch (error) {
      commit('SET_ERROR', error);
    }
  },
  resolveCallAttempt ({commit}) {
    commit('SET_CALL_ATTEMPT_INITIATED', false);
  },
  addCallInProgress ({ state, commit }, val) {
    const existingCall = state.callsInProgress.some(call => call.callId === val.callId);
    if (existingCall) { return };
    commit('ADD_CALL_IN_PROGRESS', val);
  },
  setCallTarget({state, commit}, {callId, target}) {
    const updatedCallsInProgress = state.callsInProgress.map(call => {
      if (call.callId === callId) {
        call.target = target;
      };
      return call;
    })
    commit('UPDATE_CALLS_IN_PROGRESS', updatedCallsInProgress);
  },
  storeProfileForPlacedCall({commit}, profile) {
    commit('UPDATE_STORED_PROFILE_FOR_PLACED_CALL', profile);
  },
  clearStoredProfile({commit}) {
    commit('UPDATE_STORED_PROFILE_FOR_PLACED_CALL', null);
  },
  removeCallInProgress( {commit}, callId ) {
    commit('REMOVE_CALL_IN_PROGRESS', callId);
  },
  setLogId({commit}, {callId, logId}) {
    const updatedCallsInProgress = state.callsInProgress.map(call => {
      if (call.callId === callId) {
        call.logId = logId;
      };
      return call;
    })
    commit('UPDATE_CALLS_IN_PROGRESS', updatedCallsInProgress);
  },
  async getLogIdsForCallsInProgress({ state, commit }) {
    for (const call of state.callsInProgress) {
      if (!call.logId) {
        const callLog = await this.$apiv2.getCommunicationLogByCallId(call.callId);
        if (callLog?.id) {
          const updatedCallsInProgress = state.callsInProgress.map(callInProgress => {
            if (callInProgress.callId === call.callId) {
              callInProgress.logId = callLog.id;
            };
            return callInProgress;
          })
          commit('UPDATE_CALLS_IN_PROGRESS', updatedCallsInProgress);
        }
      }
    }
  },
};

export const getters = {
  callParticipantPhoneNumber: (state) => (callInProgress) => {
    const call = state.callsInProgress.find(call => call.callId === callInProgress.callId);
    if (!call) { return null };
    return call.direction === 'inbound' ? call.caller?.phoneNumber : call.callee?.phoneNumber;
  },
  callParticipantName: (state) => (callInProgress) => {
    const call = state.callsInProgress.find(call => call.callId === callInProgress.callId);
    if (!call) { return null };
    if (call.target?.profile) {
      return `${call.target?.profile.firstName} ${call.target?.profile.lastName}`;
    }
    if (call.direction === 'inbound') {
      return call.caller?.name || 'Unknown';
    } else {
      return call.callee?.name || 'Unknown';
    }
  },
};
