import { ref, computed } from "@vue/composition-api";

const defaultFetchParams = {
  sortField: 'name',
  sortOrder: 1,
  filters: {
    npi: {
      matchMode: 'equals',
      value: null,
    }, 
    firstName: {
      matchMode: 'contains',
      value: null,
    },
    lastName: {
      matchMode: 'contains',
      value: null,
    },
  },
}

const defaultSearchFields = {
  npi: {
    validFilters: ['equals'],
  },
  firstName: {
    validFilters: ['equals','contains'],
  },
  lastName: {
    validFilters: ['equals','contains'],
  },
}

export function usePractitionerSearch (searchFields = defaultSearchFields, initialFetchParams=defaultFetchParams) {
  // State
  const loadingInternal = ref(false);
  const loadingRegistry = ref(false);
  const loadingSites = ref(false);
  const matches = ref(null);
  const fetchParams = ref({...initialFetchParams});
  const showingNpiResults = ref(false);
  const error = ref(null);
  const totalRecords = ref(0);
  const latestSearchRef = ref(0);
  const latestSiteSearchRef = ref(0);
  const selectedSite = ref(null);
  const siteOptions = ref([]);

  // Getters
  const getFetchParamValue = computed(() => (filterKey, filterParamKey) => {
    if (!fetchParams.value?.filters) {
      return undefined;
    }
    return fetchParams.value.filters[filterKey] ? fetchParams.value.filters[filterKey][filterParamKey] : null;
  });

  const flexibleMatchCompatibleFields = computed(() => {
    return Object.keys(searchFields).filter(field => searchFields[field].validFilters?.includes('contains'))
  });

  const isFlexibleMatchCompatibleField = computed(() => (fieldName) => {
    return flexibleMatchCompatibleFields.value.includes(fieldName);
  });

  const selectedSitePractitioners = computed(() => {
    return selectedSite.value?.practitioners || [];
  });

  const matchesWithinSelectedSite = computed(() => {
    return matches.value && matches.value.filter(p => selectedSitePractitioners.value.some(sp => sp.npi === p.npi));
  });
  
  const matchesOutsideSelectedSite = computed(() => {
    return matches.value && matches.value.filter(p => !selectedSitePractitioners.value.some(sp => sp.npi === p.npi));
  });

  // Actions
  async function searchPractitioners () {
    loadingInternal.value = true;
    showingNpiResults.value = false;
    const searchParams = {
      ...fetchParams.value,
      filters: Object.entries(fetchParams.value.filters).reduce((acc, [key, val]) => {
        // Do not include values that are empty strings
        if (val?.value !== '') {
          return {
            ...acc,
            [key]: val,
          }
        }
        return acc;
      },{})
    };
    
    latestSearchRef.value += 1;
    const searchRef = latestSearchRef.value;

    const {data, headers} = await this.$apiv2.searchPractitioners(searchParams);
    
    // Do not update results if this is not the latest search.
    if (searchRef !== latestSearchRef.value) {return}

    totalRecords.value = headers['x-total-count'];
    if (data.length) {
      setMatches(data);
    } else {
      searchNpiRegistry.call(this);
    }
    loadingInternal.value = false;
  }

  async function searchNpiRegistry () {
    loadingRegistry.value = true;
    showingNpiResults.value = true;

    // Maps the search filters to NPI registry api query string format
    const queryParams = Object.entries(fetchParams.value.filters).filter(([_, {value}]) => !!value).map(([fieldName, {matchMode, value}]) => {
      let queryString = `${fieldName}=${value}`;
      if (matchMode === 'contains') {
        queryString += '*';   // NPI Registry API allows an * after 2 characters to indicate a wild card
      }
      return queryString;
    });
    
    latestSearchRef.value += 1;
    const searchRef = latestSearchRef.value;

    const {data: results} = await this.$apiv2.searchNpiRegistry(queryParams);

    if (searchRef !== latestSearchRef.value) {return} // Do not update results if this is not the latest search.

    setMatches(results);
    totalRecords.value = results.length;
    loadingRegistry.value = false;
  }

  async function searchSites(searchString, sites) {
    loadingSites.value = true;

    latestSiteSearchRef.value += 1;
    const siteSearchRef = latestSiteSearchRef.value;

    const data = sites.filter(s => {
      return s.full_name?.toLowerCase().includes(searchString.toLowerCase()) || s.short_name?.toLowerCase().includes(searchString.toLowerCase())
    });

    if (siteSearchRef !== latestSiteSearchRef.value) {return} // Do not update results if this is not the latest search.

    if (data) {
      setSiteOptions(data);
    }

    loadingSites.value = false;
  }

  async function assignPractitionerToSite(practitioner) {
    loadingSites.value = true;
    error.value = false;
    if (selectedSite.value?.id && practitioner?.npi) {
      try {
        const {data: site} = await this.$apiv2.assignPractitionersToSite({siteId: selectedSite.value.id, practitioners: [practitioner.npi]});
        if (site && site.id === selectedSite.value?.id) {
          // Update the selected site and recast long_name and short_name to camelCase.
          setSelectedSite({
            ...site,
            long_name: site.longName,
            short_name: site.shortName,
          })
        }
      } catch(err) {
        setError(err);
      }
    } else if (!selectedSite.value?.id) {
      console.error('Invalid site provided: ', selectedSite.value);
      setError('Please reselect the Group/Practice to which you would like to add this practitioner');
    } else {
      console.error('Invalid practitioner provided: ', practitioner);
      setError('Please reselect the practitioner you would like added to this site');
    }
  }

  async function createPractitioner(practitioner) {
    const {data: createdPractitioner} = await this.$apiv2.fetchPractitioner(practitioner.npi);
    await assignPractitionerToSite.call(this, createdPractitioner);
  }

  function setFetchParams(params) {
    fetchParams.value = params;
  }

  function updateFetchParams(filterKey, filterParamKey, value) {
    setFetchParams({
      ...fetchParams.value,
      filters: {
        ...fetchParams.value.filters,
        [filterKey]: {
          ...fetchParams.value.filters[filterKey],
          [filterParamKey]: value,
        }
      },
    });
  }

  function resetFetchParams() {
    setFetchParams({...initialFetchParams});
  }

  async function fetchSite(site) {
    if (!site.id) { return }
    
    const {data: detailedSite} = await this.$apiv2.getDetailedSiteById(site.id);

    if (detailedSite) {
      // Update the selected site with full site info (incl. practitioners) and recast longName and shortName to snake_case
      setSelectedSite({
        ...detailedSite,
        long_name: detailedSite.longName,
        short_name: detailedSite.shortName,
        full_name: detailedSite.fullName,
      });
    }
  }

  function setSelectedSite(val) {
    selectedSite.value = val;
  }

  function setSiteOptions(arr) {
    siteOptions.value = arr;
  }

  function setMatches(val) {
    matches.value = val;
  }

  function setLoadingInternal(val) {
    loadingInternal.value = val;
  }

  function setloadingRegistry(val) {
    loadingRegistry.value = val;
  }

  function setLoadingSites(val) {
    loadingSites.value = val;
  }

  function setError(val) {
    error.value = val;
  }

  return {
    // State
    loadingInternal,
    loadingRegistry,
    matches,
    fetchParams,
    showingNpiResults,
    error,
    totalRecords,
    selectedSite,
    siteOptions,

    // Getters
    getFetchParamValue,
    flexibleMatchCompatibleFields,
    isFlexibleMatchCompatibleField,
    selectedSitePractitioners,
    matchesWithinSelectedSite,
    matchesOutsideSelectedSite,

    // Actions
    searchPractitioners,
    searchNpiRegistry,
    assignPractitionerToSite,
    createPractitioner,
    setFetchParams,
    updateFetchParams,
    resetFetchParams,
    fetchSite,
    setSelectedSite,
    setSiteOptions,
    searchSites,
    setMatches,
    setLoadingInternal,
    setloadingRegistry,
    setLoadingSites,
    setError,
  };
}
