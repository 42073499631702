export const state = () => ({
  isEditMode: false,
  profileCareTeam: [],
  siteCareTeam: [],
  siteCareTeamByRole: {},
  careTeamUpdateArr: [],
  loading: true,
  lastUpdatedDate: null,
})

export const getters = {
  profileCareTeamByRole: (state, getters, rootState) => rootState.portal.allSiteUserRoles.map(role => {
    const member = state.profileCareTeam.find(member => member.roles.some(r => r.code === role.code));
    return member ? { userId: member.id, role: role.code } : null;
  }).filter(item => item !== null),
  lastUpdatedDateFromNow: (state) => state.lastUpdatedDate ? moment(state.lastUpdatedDate).fromNow() : null,
  getCareTeamMemberHasRole: (state) => (role) => {
    return state.profileCareTeam.find((member) => member.roles.some((r) => r.code === role.code));
  },
  careTeamOptions: (state) => (roleCode) => {
    return state.siteCareTeam.filter(member => member.roles.some(r => r.code === roleCode));
  },
}

export const mutations = {
  setEditMode (state, mode) {
    state.isEditMode = mode;
  },
  setProfileCareTeam (state, team) {
    state.profileCareTeam = team;
  },
  setSiteCareTeam (state, team) {
    state.siteCareTeam = team;
  },
  setSiteCareTeamByRole (state, team) {
    state.siteCareTeamByRole = team;
  },
  setCareTeamUpdateArr (state, arr) {
    state.careTeamUpdateArr = arr;
  },
  setLoading (state, loading) {
    state.loading = loading;
  },
  setLastUpdatedDate (state, date) {
    state.lastUpdatedDate = date;
  },
  addToCareTeamUpdateArr (state, { userId, role }) {
    const index = state.careTeamUpdateArr.findIndex(item => item.role === role);
    if (index !== -1) {
      state.careTeamUpdateArr[index] = { userId, role };
    } else {
      state.careTeamUpdateArr.push({ userId, role });
    }
  },
}

export const actions = {
  async initProfileCareTeam ({ dispatch, commit }, { profileId, siteId }) {
    commit('setLoading', true);
    await Promise.all([dispatch('fetchCareTeamForProfile', profileId), dispatch('fetchCareTeamForSite', siteId)]);
    commit('setLoading', false);
  },
  async fetchCareTeamForProfile ({ commit }, profileId) {
    const response = await this.$apiv2.getCareTeamForProfile(profileId);
    commit('setProfileCareTeam', response);
    return response;
  },
  async fetchCareTeamForSite ({ commit, state }, siteId) {
    const response = await this.$apiv2.getTeamMembersForSite(siteId);
    commit('setSiteCareTeam', response);
    return response;
  },
  async updateCareTeamForProfile ({ commit, state, getters }, { profileId }) {
    commit('setLoading', true);
    try {
      const payload = getters.profileCareTeamByRole;
      state.careTeamUpdateArr.forEach(item => {
        const index = payload.findIndex(p => p.role === item.role);
        if (index !== -1) {
          if (item.userId !== null) {
            payload[index] = item;
          } else {
            payload.splice(index, 1);
          }
        } else {
          payload.push(item);
        }
      });

      const response = await this.$apiv2.updateTeamMembersForProfile(profileId, payload);
      commit('setProfileCareTeam', response);
    } catch (e) {
      console.log(e)
      throw e
    } finally {
      commit('setLoading', false);
      commit('setEditMode', false);
    }
  },
  addCareTeamToUpdateArr ({ commit }, { userId, role }) {
    commit('addToCareTeamUpdateArr', { userId, role });
  },
  toggleEditMode ({ commit }) {
    commit('setEditMode', !state.isEditMode);
  },
  discardChanges ({ commit }) {
    commit('setCareTeamUpdateArr', []);
    commit('setEditMode', false);
  }
}