//----------------------------------------------------------------------------
// State
//----------------------------------------------------------------------------

import SpirometrySession from "@/components/spirometry2019/models/SpirometrySession";

const STATE_VALUES_DEFAULT = {
  showOverreadingDialogForId: undefined,

  session: undefined,
  validationError: undefined,
  changed: false,
  canSave: false
};

export const state = () => (STATE_VALUES_DEFAULT);

function validateChanges(state) {
  state.validationError = state.session.overwrite.error;
  state.changed = state.session.overwrite.changed;
  state.canSave = !state.validationError && state.changed;
}

//----------------------------------------------------------------------------
// Mutations
//----------------------------------------------------------------------------
export const mutations = {
  showOverreadingDialog: function(state, sessionId) {
    state.showOverreadingDialogForId = sessionId;
  },
  closeOverreadingDialog: function(state) {
    state.showOverreadingDialogForId = undefined;
  },
  discardChanges(state) {
    state.session.overwrite.discardChanges();
    validateChanges(state);
  },
  setSession: function(state, session) {
    state.session = new SpirometrySession(session);
    validateChanges(state);
  },
  setBestTest: function(state, testId) {
    state.session.overwrite.bestTestId = testId;
    validateChanges(state);
  },
  setBestFvcTest: function(state, testId) {
    state.session.overwrite.bestFvcTestId = testId;
    validateChanges(state);
  },
  setBestFev1Test: function(state, testId) {
    state.session.overwrite.bestFev1TestId = testId;
    validateChanges(state);
  },
  setBestPefTest: function(state, testId) {
    state.session.overwrite.bestPefTestId = testId;
    validateChanges(state);
  },
  setTestFvcStatus: function(state, { testId, status }) {
    state.session.overwrite.setTestFvcStatus(testId, status);
    validateChanges(state);
  },
  setTestFev1Status: function(state, { testId, status }) {
    state.session.overwrite.setTestFev1Status(testId, status);
    validateChanges(state);
  }
};