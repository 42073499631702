<template>
  <div :class="`flex vertical full-height overflow-hidden ${maximized ? 'sms-summary pt-5 pr-6 pb-0 pl-0' : ''}`">
    <div v-if="maximized" class="sms-header flex-row align-items-center font-medium">
      <span>Messages</span>
    </div>
    <div class="field mt-3">
      <span class="p-float-label">
        <Dropdown id="siteFilter" filter v-model="selectedSite" class="site-dropdown w-full" :options="sitesSortedByFullName"
          optionLabel="full_name" showClear />
        <label for="siteFilter">Site</label>
      </span>
    </div>
    <div class="field">
      <span class="p-float-label">
        <Dropdown id="assignedTo" v-model="channelOwner" class="site-dropdown w-full" :options="ownerOptions"
          optionLabel="label" optionValue="value" />
        <label for="assignedTo">Assigned to</label>
      </span>
    </div>

    <InlineMessage v-if="error" class="flex flex-grow-0 justify-content-start align-items-center" severity="error">{{error.message || 'SMS service has encountered an error'}}</InlineMessage>
    
    <TabView>
      <TabPanel header="SMS">
        <div class="sms-message-list">
          <SmsSidebarMessageListItem v-for="(channel, index) in filteredChannels" :channel="channel"
            @openChannel="openChannel_clickHandler" :key="index" />
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>
  
<script>

import SmsSidebarMessageListItem from "@/components/layout/sidebar/sidebar-panels/sms/SmsSidebarMessageListItem";
import { createNamespacedHelpers } from 'vuex';
import { OWNER } from "./constants";
import { setupScrollDetection } from "@/helpers";

const messagingVuex = createNamespacedHelpers('messaging');
const portalVuex = createNamespacedHelpers('portal');

export default {
  components: {
    SmsSidebarMessageListItem,
  },
  props: {
    maximized: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      selectedSite: undefined,
      channelOwner: null,
    }
  },
  mounted () {
    this.channelOwner = this.selectedChannelOwner;
    setupScrollDetection('.sms-message-list', 'show-scrollbar');
  },
  computed: {
    ...portalVuex.mapGetters(['sitesSortedByFullName']),
    ...messagingVuex.mapState(['channels', 'selectedChannelOwner', 'error']),
    ownerOptions () {
      return [
        { label: 'All', value: OWNER.ALL },
        { label: 'Myself', value: OWNER.ME },
        { label: 'Others', value: OWNER.OTHER },
      ]
    },
    filteredChannels () {
      const filterBySelectedSite = (channel) =>
        !this.selectedSite || channel.tenants.includes(this.selectedSite.id);

      return this.channels.filter(filterBySelectedSite).reverse();
    },
  },
  methods: {
    ...messagingVuex.mapActions(['changeSelectedChannelOwner']),
    openChannel_clickHandler (channel) {
      this.$emit('openChannel', channel);
    },
  },
  watch: {
    channelOwner (val) {
      this.changeSelectedChannelOwner(val)
    },
  }
}
</script>
<style lang="scss" scoped>
.sms-summary {
  max-width: 400px;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.2);
}

.sms-header {
  display: flex;
  font-size: 24px;
}

.site-dropdown {
  ::v-deep .p-dropdown-clear-icon {
    top: 43%;
  }
}

.sms-message-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  overflow-y: auto;
}

::v-deep .p-tabview,
.p-tabview-panel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: auto;
  overflow: hidden;

  .p-tabview-panels {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    padding: 0;
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.show-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 4px;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background: #609af8;
}
</style>