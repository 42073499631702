<template>
  <SidebarContent :id="id" :payload="payload">
    <template v-slot:sidebar-header>
      <div class="flex align-items-center font-medium text-2xl">
        <span>Log</span>
      </div>
    </template>

    <template v-if="waitingForLogCreation" v-slot:sidebar-content>
      <div class="flex flex-column align-items-center mt-3 text-lg line-height-2">
        <ProgressSpinner class="my-3" style="width: 50px; height: 50px" v-if="waitingForLogCreation" />
        <span>Waiting for call to be answered...</span>
      </div>
    </template>

    <template v-else v-slot:sidebar-content>
      <div v-if="loading || submitting">
        <span>{{loading ? "Loading..." : "Saving..."}}</span>
      </div>
      <div v-else-if="error" class="flex align-items-center">
        <span class="text-red-500">Error with log.</span>
        <Button icon="pi pi-replay" class="p-button-rounded p-button-danger p-button-text p-button-sm ml-2"
          @click="refreshLog" />
      </div>
      <div v-else class="log-wrapper">
        <div class="flex flex-grow-0 flex-row align-items-center">
          <div v-if="profile" class="mr-10">
            <div class="flex flex-row mt-3 text-lg line-height-2">
              <span class="mr-1">Patient:</span>
              <span class="font-medium profile-link" @click="navigate_clickHandler">{{displayName}}</span>
            </div>
            <div class="flex text-lg line-height-2">
              <span>DOB: {{displayDOB}}</span>
            </div>
            <div class="flex mb-3 text-lg line-height-2">
              <span>Site: {{displaySite}}</span>
            </div>
          </div>
          <div v-else class="mr-10 my-3">
            <InlineMessage severity="error">Patient assignment required.</InlineMessage>
          </div>
          <div class="ml-10">
            <span id="reassign-button" class="profile-link" @click="reassignPatient_clickHandler" aria:haspopup="true" aria-controls="logSearchOverlay">{{profile ? "Reassign" : "Assign"}}</span>
            <OverlayPanel ref="logSearchOverlay" appendTo="body" id="log-search-overlay" :autoZIndex="false" :dismissable="false" showCloseIcon>
              <PatientSearch @toggleOverlay="toggleSearchOverlay" @profileSelected="profile_clickHandler" applySiteAccess />
            </OverlayPanel>
          </div>
        </div>
        <LogActivityForm v-if="log.id" :logToEdit.sync="log" :disabledFields="disabledFields" :hiddenFields="hiddenFields"
          :pastActivity="pastActivity" :siteId="preferredSite ? preferredSite.id : null">
        </LogActivityForm>
        <div class="log-form-button-bar">
          <Button class="selector p-button" @click="saveChanges" :disabled="!canSubmit">Save Changes</Button>
        </div>
        <div :class="notesClass">
          <LogNotes :logId="log.id" :notes="displayNotes" disallowNewNotes @add-note="submitNote"
            @update-note="updateNote" @delete-note="deleteNote" verticalNoteForm />
        </div>
      </div>
    </template>

    <template v-slot:sidebar-footer>
    </template>
  </SidebarContent>
</template>

<script>
import LogActivityForm from '@/components/communication/LogActivityForm';
import LogNotes from '@/components/common/LogNotes.vue'
import PatientSearch from "@/components/common/PatientSearch";
import { systemNoteSubjects } from "@/components/communication/constants";
import SidebarContent from "@/components/layout/sidebar/SidebarContent";
import { slugFromSite } from "@/utils/modelUtil";
import { setupScrollDetection } from "@/helpers";
import { useActivityLogForm } from '@/composables/activityLogForm';
import { ref, computed, onMounted, watch } from '@vue/composition-api';

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    payload: {
      type: Object,
      required: false,
    },
  },
  components: {
    SidebarContent,
    LogActivityForm,
    LogNotes,
    PatientSearch,
  },
  setup (props, { root, refs }) {
    const originalLog = ref({});
    const sidebarId = computed(() => props.id);
    const logToEdit = computed(() => ({...originalLog.value}));
    const pastActivity = computed(() => true);
    const prefill = computed(() => false);

    const useActivityLogFormComposable = useActivityLogForm(logToEdit, pastActivity, prefill);
    const { log, disabledFields, hiddenFields, isComplete, hasChanges, patchLogPayload,
      replaceLog, updateLogValues } = useActivityLogFormComposable;

    const profile = ref(undefined);
    const loading = ref(false);
    const submitting = ref(false);
    const waitingForLogCreation = ref(false);
    const error = ref(false);

    // Store State and Getters
    const callsInProgress = computed(() => root.$store.state.zoom.callsInProgress);
    const storedProfileForPlacedCall = computed(() => root.$store.state.zoom.storedProfileForPlacedCall);
    const sites = computed(() => root.$store.state.portal.sites);
    const currentSiteId = computed(() => root.$store.getters['portal/currentSiteId']);

    // Computed
    const displayName = computed(() =>  profile.value ? `${profile.value.firstName} ${profile.value.lastName}` : 'No profile match found.');
    const displayDOB = computed(() => profile.value ? profile.value.dateOfBirth : 'N/A');
    const displaySite = computed(() => preferredSite.value?.long_name || preferredSite.value?.longName || 'Not found');
    const preferredSiteProfile = computed(() => {
      const siteProfiles = profile.value?.siteProfiles || [];
      const usableProfiles = siteProfiles.filter(sp => sites.value.map(site => site.id).includes(sp.site.id));
      if (usableProfiles?.length) {
          return usableProfiles.find(sp => 
              (sp.site.id === props.payload.site?.id) ||   // If a particular site was passed into the payload, prefer that site.
              (currentSiteId.value && sp.site.id === currentSiteId.value)  // Else if the user is currently viewing a particular site, prefer that site.
          ) || usableProfiles[0];
      }
      return undefined;
    });
    const preferredSite = computed(() => preferredSiteProfile.value?.site);
    
    const canSubmit = computed(() => isComplete.value && profile.value?.id && hasChanges.value);
    const displayNotes = computed(() => log.value.notes?.filter(n => !n.system && !systemNoteSubjects.includes(n.subject)));
    const notesClass = computed(() => loading.value ? "hidden" : "");

    // Store actions
    const fetchLog = (payload) => root.$store.dispatch('communicationLogs/fetchLog', payload);
    const updateLog = (payload) => root.$store.dispatch('communicationLogs/updateLog', payload);
    const addNoteToLog = (payload) => root.$store.dispatch('communicationLogs/addNoteToLog', payload);
    const updateNoteOnLog = (payload) => root.$store.dispatch('communicationLogs/updateNoteOnLog', payload);
    const deleteNoteOnLog = (payload) => root.$store.dispatch('communicationLogs/deleteNoteOnLog', payload);
    const setCallTarget = (payload) => root.$store.dispatch('zoom/setCallTarget', payload);
    const clearStoredProfile = () => root.$store.dispatch('zoom/clearStoredProfile');

    // Actions
    const refreshLog = async() => {
      if (!log.value.id && !props.payload.callId) {
        error.value = true;
        throw new Error('Communication log sidebar requires either a logId or callId in its payload');
      }
      if (!log.value.id) {
        waitingForLogCreation.value = true;
        return;
      }
      loading.value = true;
      try {
        error.value = false;
        const fetchedLog = await fetchLog(log.value.id);
        if (!fetchedLog) {
          throw new Error('Error fetching log id ', log.value.id);
        }
        originalLog.value = { 
          ...fetchedLog, 
          logDate: new Date(fetchedLog.sent),
        };
        if (fetchedLog.encounter?.durationInSeconds) {
          originalLog.value.duration = fetchedLog.encounter?.durationInSeconds / 60;
        }
        replaceLog({ ...originalLog.value });
        await updateProfile();
      } catch (err) {
        console.error(err);
        error.value = true;
      }
      loading.value = false;
    }

    const checkForLogId = async(callList) => {
      if (props.payload.callId && callList.length) {
        const associatedCall = callList.find(callInProgress => callInProgress.callId === props.payload.callId);
        if (associatedCall && associatedCall.logId) {
          updateLogValues({id: associatedCall.logId});
          waitingForLogCreation.value = false;
          refreshLog();
        }
      }
    }
    
    const updateProfile = async() => {
      profile.value = await loadProfile();
    }

    const loadProfile = async() => {
      if (log.value.principal) {
        try {
          // Bypasses the default error display since some logs are assigned a generic id that will result in 404 from this request.
          const response = await root.$apiv2.getProfileById(log.value.principal, true);
          let profile = response.data;
          if (shouldUseStoredProfile(profile)) {
            updateLogValues({principal: storedProfileForPlacedCall.value.id});
            clearStoredProfile();
            submitLog();
            return undefined; // The field casing is different between a stored profile and a fetched profile, so we don't return the stored one.
          }
          return {
            ...profile,
            fullName: `${profile.firstName} ${profile.lastName}`,
          }
        } catch (err) {
          console.error('Error retrieving profile for log: ', log.value.principal);
          root.$toast.add({ severity: 'error', summary: 'No profile match for this caller', detail: `Please assign one.`, life: 3000 });
        }
      }
      return undefined;
    }

    const shouldUseStoredProfile = (fetchedProfile) => { 
      if (!storedProfileForPlacedCall.value) {
        return false;
      }
      if (storedProfileForPlacedCall.value.id === fetchedProfile.id) { 
        clearStoredProfile();  // Call was correctly assigned to profile, so stored profile can be cleared.
        return false 
      };
      // If the stored profile shares a phone number with the profile assigned to the log, it was likely assigned incorrectly.
      return (storedProfileForPlacedCall.value.phones || []).some(phone => {
        return fetchedProfile.phones?.find(fetchedPhone => fetchedPhone.phoneNumber === phone.phone_number)
      });
    }
    
    const navigate_clickHandler = () => {
      if (preferredSiteProfile.value) {
        root.$router.push({ path: `/site/${slugFromSite(preferredSiteProfile.value.site)}/patient/${preferredSiteProfile.value.id}` })
      }
    }

    const saveChanges = async () => {
      await submitLog();
      if (!error.value) {
        root.$toast.add({ severity: 'success', summary: 'Success', detail: `Log saved`, life: 3000 });
        root.$store.dispatch('sidebar/removeSidebar', sidebarId.value);
      }
    }
    
    const submitLog = async () => {
      submitting.value = true;
      try {
        // Separate out noteText, and notes since we submit them separately.
        const { noteText, notes, ...patchedLog } = {id: log.value.id, ...patchLogPayload.value};
        if (noteText) {
          await submitNote({ logId: log.value.id, notePayload: { text: noteText } });
        }
        const updatedLog = await updateLog({ logPayload: patchedLog });
        if (!updatedLog) {
          throw new Error('Error updating log id ', log.value.id);
        }
        originalLog.value = { 
          ...updatedLog, 
          logDate: pastActivity ? new Date(updatedLog.sent) : new Date(updatedLog.startTime), 
        };
        if (updatedLog.encounter?.durationInSeconds) {
          originalLog.value.duration = updatedLog.encounter?.durationInSeconds / 60;
        }
        replaceLog({ ...originalLog.value });
        await updateProfile();
      } catch (err) {
        console.error(err);
        error.value = true;
      }
      submitting.value = false;
    }
    
    const submitNote = async(note) => {
      loading.value = true;
      try {
        const addedNote = await addNoteToLog(note);
        if (!addedNote) {
          throw new Error('Error adding note ', note);
        }
      } catch (err) {
        console.error(err);
        error.value = true;
      }
      loading.value = false;
    }

    const updateNote = async(note) => {
      loading.value = true;
      try {
        const updatedNote = await updateNoteOnLog(note);
        if (!updatedNote) {
          throw new Error('Error updating note ', note);
        }
        const noteIndex = log.value.notes.findIndex(note => note.id === updatedNote.id);
        if (noteIndex !== -1) {
          [...log.value.notes].splice(noteIndex, 1, updatedNote);
        }
      } catch (err) {
        console.error(err);
        error.value = true;
      }
      loading.value = false;
    }

    const deleteNote = async(note) => {
      loading.value = true;
      try {
        const deletedNote = await deleteNoteOnLog(note);
        if (!deletedNote) {
          throw new Error('Error deleting note ', note);
        }
        log.value.notes = log.value.notes.filter(logNote => logNote.id !== note.noteId);
      } catch (err) {
        console.error(err);
        error.value = true;
      }
      loading.value = false;
    }

    const reassignPatient_clickHandler = (e) => {
      toggleSearchOverlay(e);
    }

    const toggleSearchOverlay = (event) => {
      refs.logSearchOverlay.toggle(event);
      setTimeout(() => {
        refs.logSearchOverlay && fixOverlay('log-search-overlay');
      },500);
    }
    
    const profile_clickHandler = (selectedProfile) => {
      if (selectedProfile?.id) {
        updateLogValues({principal: selectedProfile.id});
      }
      profile.value = selectedProfile;
      toggleSearchOverlay(false)
    }
    
    const fixOverlay = (overlayId) => {
      const overlay = document.getElementById(overlayId);
      const target = document.getElementById('reassign-button');
      const targetRect = target.getBoundingClientRect();
      if (overlay && targetRect) {
        overlay.style.position = 'fixed';
        overlay.style.top = targetRect.bottom + 'px';
      } else {
        console.error('Could not find HTML element to position overlay ', overlayId);
      }
    }

    // Watchers
    watch(callsInProgress, (newValue) => {
      if (log.value.id) { return }
        checkForLogId(newValue);
    }, {deep: true});

    watch(preferredSiteProfile, () => {
      if (props.payload.callId) {
          const call = callsInProgress.value.find(call => call.callId === props.payload.callId);
          if (call) {
            setCallTarget({
              callId: props.payload.callId,
              target: call.target ? {
                ...call.target,
                profile: profile.value,
              } : { profile: profile.value }
            });
          }
        }
    }, {deep: true});

    // Lifecycle hooks
    onMounted(async () => {
      updateLogValues({id: props.payload.logId});
      await refreshLog();
      setupScrollDetection('.log-wrapper', 'show-scrollbar');
    });

    return {
      log,
      profile,
      loading,
      submitting,
      waitingForLogCreation,
      error,
      hiddenFields,
      disabledFields,
      displayName,
      pastActivity,
      displayDOB,
      displaySite,
      preferredSite,
      canSubmit,
      displayNotes,
      notesClass,
      refreshLog,
      navigate_clickHandler,
      saveChanges,
      submitNote,
      updateNote,
      deleteNote,
      reassignPatient_clickHandler,
      toggleSearchOverlay,
      profile_clickHandler,
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebar-main {
  padding: 20px 24px 24px;
}

.profile-link {
  color: #0073e6;
  cursor: pointer;
}

.invalid {
  .p-autocomplete {
    border: solid red 1px;
    border-radius: 8px;
  }
}

.patient-search {
  ::v-deep .p-autocomplete-input {
    width: 100%;
  }
}

.divider {
  background-color: #CCC;
  width: 100%;
  height: 2px;
  margin: 10px 0;
}

.log-wrapper {
  display: flex;
  flex-direction: column;
  flex: 0 auto;
  overflow-y: auto;
}

.log-form-button-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  button {
    margin: 16px 0 16px 16px;
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.show-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 4px;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background: #609af8;
}

#log-search-overlay {
  z-index: 200;
  ::v-deep .p-overlaypanel-close {
    background-color: transparent;
    color: grey;
    top: 11px;
    right: 12px;
  }
}
</style>
