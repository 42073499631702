export const SIDEBAR_TAGS = {
  ZOOM: 'zoom',
  COMMUNICATION_LOG: 'communication-log',
  ZOOM_TRANSCRIPT: 'zoom-transcript',
  SMS: 'sms',
  CALENDAR: 'calendar',
}

export const SIDEBAR_COMPONENTS = {
  [SIDEBAR_TAGS.ZOOM]: 'ZoomSidebar',
  [SIDEBAR_TAGS.COMMUNICATION_LOG]: 'CommunicationLogSidebar',
  [SIDEBAR_TAGS.ZOOM_TRANSCRIPT]: 'ZoomTranscriptSidebar',
  [SIDEBAR_TAGS.SMS]: 'SmsSidebar',
  [SIDEBAR_TAGS.CALENDAR]: 'CalendarSidebar',
}

export const PERSISTENT_SIDEBARS = [SIDEBAR_TAGS.ZOOM];
