<template>
  <div class="sms-message-thread-item" :class="selfClass">
    <span v-if="isSent" class="secondary-text">{{ message.sender }}</span>
    <div class="sms-message-content">{{ message.textMessage }}</div>
    <div class="flex flex-row justify-content-end">
      <span v-if="messageStatus" class="secondary-text">{{ messageStatus }}</span>
      <span v-if="isSent && isSMS" class="secondary-text ml-1">(SMS)</span>
    </div>
  </div>
</template>
  
<script>

export default {
  components: {},
  data () {
    return {}
  },
  props: {
    message: {
      type: Object,
      required: true
    },
    channel: {
      type: Object,
      required: true,
    },
  },
  mounted () { },
  computed: {
    isSent () {
      return this.message.sender !== this.channel?.patientFullName;
    },
    selfClass () {
      return `${this.isSent ? 'sent' : 'received'} ${this.messageStatus === 'Failed' ? 'failed' : ''}`;
    },
    isSMS () {
      return this.message.source !== 'SMS';
    },
    messageStatus() {
      if (!this.isSent) { 
        return ''
      }
      switch (this.message.status?.toLowerCase()) {
        case 'delivered':
          return 'Delivered';
        case 'sent':
          return 'Sent';
        case 'accepted' || 'sending' || 'queued':
          return 'Sending...';
        case 'scheduled':
          return 'Scheduled';
        case 'canceled': 
          return 'Canceled';
        case 'read':
          return 'Read';
        case 'failed' || 'undelivered':
          return 'Failed';
        default:
          return '';
      }
    },
  },
  methods: {},
  watch: {}
}
</script>
<style lang="scss" scoped>
.sms-message-thread-item {
  display: flex;
  flex-direction: column;
  margin: 6px 0;

  .secondary-text {
    opacity: 0.5;
    align-self: flex-end;
  }
}

.sms-message-content {
  margin: 2px 0;
  padding: 4px 10px;
  border-radius: 12px;
  width: fit-content;
  max-width: 280px;
}

.sent {
  align-self: flex-end;

  .sms-message-content {
    align-self: flex-end;
    background-color: #99CCFF;
  }
}

.received {
  .sms-message-content {
    background-color: #D3D3D3;
  }
}

.failed {
  .sms-message-content {
    background-color: #FFE7E6;
  }
}
</style>
