const cachedOverwritingsById = {};

const OVERWRITTEN_VALUES_DEFAULT = {
  excludedTestIds: [],
  comment: undefined,
  bestTestId: undefined,
  fev1SelectedTestId: undefined,
  fvcSelectedTestId: undefined,
  pefSelectedTestId: undefined,
  createdBySiteUserId: undefined,
  validationErrors: [],
  canSave: false,
  changed: false
};

const STATE_DEFAULT = {
  showOverwritingDialog: false,
  siteUser: undefined,
  selectedSession: undefined,

  /**
   * These are the overwritten value as loaded from the last call to the server (if there was a previous
   * overwriting)
   */
  loadedOverwrittenValues: undefined,
  /**
   * These are the current overwritten values, which may not yet be saved
   */
  overwrittenValues: OVERWRITTEN_VALUES_DEFAULT
};

export const state = () => STATE_DEFAULT;

function mapLoadedOverwrittenValuesToState(state, overwriting) {
  // Map the values over from the previous overwriting.
  state.overwrittenValues = {
    ...OVERWRITTEN_VALUES_DEFAULT,
    bestTestId: overwriting.best_test,
    excludedTestIds: overwriting.excluded_test_ids,
    comment: overwriting.comment_thread ? overwriting.comment_thread.subthreads[0].comments[0].text : '',
    fev1SelectedTestId: overwriting.fev1_selected_test,
    fvcSelectedTestId: overwriting.fvc_selected_test,
    pefSelectedTestId: overwriting.pef_selected_test
  };
}

function validate(state) {
  let changed = false;

  if (!state.loadedOverwrittenValues) {
    const bestTestIdChanged = !!state.overwrittenValues.bestTestId;
    const commentChanged = !!state.overwrittenValues.comment;
    const fev1SelectedTestIdChanged = !!state.overwrittenValues.fev1SelectedTestId;
    const fvcSelectedTestIdChanged = !!state.overwrittenValues.fvcSelectedTestId;
    const pefSelectedTestIdChanged = !!state.overwrittenValues.pefSelectedTestId;
    const excludedTestIdsChanged = state.overwrittenValues.excludedTestIds.length !== 0;

    changed = bestTestIdChanged || commentChanged || fev1SelectedTestIdChanged || fvcSelectedTestIdChanged || pefSelectedTestIdChanged || excludedTestIdsChanged;
  } else {
    const bestTestIdChanged = state.overwrittenValues.bestTestId !== state.loadedOverwrittenValues.best_test;
    let commentChanged = false;
    if (state.loadedOverwrittenValues.comment_thread &&
        state.loadedOverwrittenValues.comment_thread.subthreads.length) {
      const { comment } = state.overwrittenValues;
      commentChanged = comment !== state.loadedOverwrittenValues.comment_thread.subthreads[0].comments[0].text;
    }
    else if (!state.loadedOverwrittenValues.comment_thread) {
      const { comment } = state.overwrittenValues;
      commentChanged = comment !== ''
    }

    const fev1SelectedTestIdChanged = state.overwrittenValues.fev1SelectedTestId !== state.loadedOverwrittenValues.fev1_selected_test;
    const fvcSelectedTestIdChanged = state.overwrittenValues.fvcSelectedTestId !== state.loadedOverwrittenValues.fvc_selected_test;
    const pefSelectedTestIdChanged = state.overwrittenValues.pefSelectedTestId !== state.loadedOverwrittenValues.pef_selected_test;

    let intersection = state.overwrittenValues.excludedTestIds.filter(x => state.loadedOverwrittenValues.excluded_test_ids.includes(x));
    const excludedTestIdsChanged = !(intersection.length === state.loadedOverwrittenValues.excluded_test_ids.length &&
      intersection.length === state.overwrittenValues.excludedTestIds.length);

    changed = bestTestIdChanged || commentChanged || fev1SelectedTestIdChanged || fvcSelectedTestIdChanged || pefSelectedTestIdChanged || excludedTestIdsChanged;
  }

  state.overwrittenValues.validationErrors = [];

  if (!changed) {
    state.overwrittenValues.validationErrors.push('You must edit at least one thing in order to save the overwriting.')
  } else {
    const { excludedTestIds } = state.overwrittenValues;
    const { best_processed_array_index } = state.selectedSession.session_values;
    const calculatedBestTestId = state.selectedSession.tests_values[best_processed_array_index].id;
    const finalBestTestId = state.overwrittenValues.bestTestId ?? calculatedBestTestId;

    const { fev1SelectedTestId, fvcSelectedTestId, pefSelectedTestId } = state.overwrittenValues;

    const bestTestIsExcluded = excludedTestIds.find(id => id === finalBestTestId);
    if (bestTestIsExcluded) {
      state.overwrittenValues.validationErrors.push('Please select a best test which is an included test.')
    }

    if (state.overwrittenValues.bestTestId && (!fev1SelectedTestId || !fvcSelectedTestId || !pefSelectedTestId)) {
      state.overwrittenValues.validationErrors.push('Please select a best FEV1, FVC, and PEF value.')
    }
  }

  state.overwrittenValues.changed = changed;
  state.overwrittenValues.canSave = state.overwrittenValues.validationErrors.length === 0;
}

export const mutations = {
  discardChanges(state) {
    if (state.loadedOverwrittenValues) {
      mapLoadedOverwrittenValuesToState(state, state.loadedOverwrittenValues);
    } else {
      state.overwrittenValues = {...OVERWRITTEN_VALUES_DEFAULT};
    }

    if (state.selectedSession) {
      cachedOverwritingsById[state.selectedSession.id] = undefined;
    }
  },
  setShowOverwritingDialog: function(state, value) {
    state.showOverwritingDialog = value;
  },
  setSiteUser(state, siteUser) {
    state.siteUser = siteUser;
  },
  setSelectedSession: function(state, session) {
    if (!session || session.id !== state.selectedSession) {
      state.overwrittenValues = {...OVERWRITTEN_VALUES_DEFAULT};
    }

    state.selectedSession = session;
    state.loadedOverwrittenValues = session.overwriting;

    if (cachedOverwritingsById[session.id]) {
      state.overwrittenValues = {...cachedOverwritingsById[session.id]};
    } else if (session.overwriting) {
      mapLoadedOverwrittenValuesToState(state, session.overwriting);
    }

    validate(state);
  },
  setOverwritingValue: function(state, payload) {
    state.overwrittenValues[payload.key] = payload.value;

    cachedOverwritingsById[state.selectedSession.id] = {...state.overwrittenValues};

    validate(state);
  },
  includeTestId: function(state, testId) {
    state.overwrittenValues = {...state.overwrittenValues, excludedTestIds: [...state.overwrittenValues.excludedTestIds.filter(id => id !== testId)] };

    validate(state);
  },
  excludeTestId: function(state, testId) {
    const excludedTestIds = [...state.overwrittenValues.excludedTestIds, testId];

    // If excluding a test that has a selected value, we need to reset that
    const bestTestId = state.overwrittenValues.bestTestId === testId ? undefined : state.overwrittenValues.bestTestId;
    const fev1SelectedTestId = state.overwrittenValues.fev1SelectedTestId === testId ? undefined : state.overwrittenValues.fev1SelectedTestId;
    const fvcSelectedTestId = state.overwrittenValues.fvcSelectedTestId === testId ? undefined : state.overwrittenValues.fvcSelectedTestId;
    const pefSelectedTestId = state.overwrittenValues.pefSelectedTestId === testId ? undefined : state.overwrittenValues.pefSelectedTestId;

    state.overwrittenValues = {
      ...state.overwrittenValues,
      excludedTestIds,
      bestTestId,
      fev1SelectedTestId,
      fvcSelectedTestId,
      pefSelectedTestId
    };

    validate(state);
  },
  setBestTestId: function(state, id) {
    // Deselect the best test if we are selecting the calculated best or they have clicked
    // the overwriting test that was already selected.
    //
    // Note: we are using null here because we want to declare, specifically, that the user
    // has chosen this action, and so in the downstream we can know that if a user deselected
    // (null) a best test that this null value should take precedence over the overwritten
    // best test we retrieved from the backend. See SpirometryChartingUtil::getBestTestIndex()
    // for example.
    let newBestTestId = (id === state.overwrittenValues.bestTestId || id === state.selectedSession.tests_values[state.selectedSession.session_values.best_processed_array_index].id) ? null : id;

    state.overwrittenValues.bestTestId = newBestTestId;

    if (!state.overwrittenValues.fev1SelectedTestId) {
      state.overwrittenValues.fev1SelectedTestId = newBestTestId;
    }
    if (!state.overwrittenValues.fvcSelectedTestId) {
      state.overwrittenValues.fvcSelectedTestId = newBestTestId;
    }
    if (!state.overwrittenValues.pefSelectedTestId) {
      state.overwrittenValues.pefSelectedTestId = newBestTestId;
    }

    validate(state);
  }
};
