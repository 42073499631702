// appointmentsStore.js
import { ref, computed, getCurrentInstance } from "@vue/composition-api";
import moment from "moment";


export function useAppointments () {
    const instance = getCurrentInstance();
    const apiv2 = instance?.proxy.$apiv2;

    const loading = ref(false);
    const loadingCareTeam = ref(false);
    const lastUpdatedDate = ref(null);
    const appointments = ref([]);
    const totalRecords = ref(0);
    const careTeamBookingLinks = ref([]);

    const SET_LOADING = (newVal) => {
        loading.value = newVal;
    }
    const SET_LOADING_CARE_TEAM = (newVal) => {
        loadingCareTeam.value = newVal;
    }
    const SET_CARE_TEAM_BOOKING_LINKS = (newVal) => {
        careTeamBookingLinks.value = newVal;
    }
    const SET_TOTAL_RECORDS = (newVal) => {
        totalRecords.value = newVal;
    }
    const SET_APPOINTMENTS = (newVal) => {
        appointments.value = newVal;
        lastUpdatedDate.value = moment();
    }
    const ADD_APPOINTMENT = (appointment) => {
        appointments.value.push(appointment);
    }
    const UPDATE_APPOINTMENT = (updatedAppointment) => {
        const appointmentIndex = appointments.value.findIndex(appointment => appointment.id === updatedAppointment.id);
        if (appointmentIndex !== -1) {
            appointments.value.splice(appointmentIndex, 1, updatedAppointment);
        }
    }
    const ADD_APPOINTMENT_NOTE = (newNote) => {
        const appointmentIndex = appointments.value.findIndex(appointment => appointment.id === newNote.appointment.id);
        if (appointmentIndex !== -1) {
            appointments.value[appointmentIndex].notes.push(newNote);
        }
    }
    const UPDATE_APPOINTMENT_NOTE = (updatedNote) => {
        const appointmentIndex = appointments.value.findIndex(appointment => appointment.id === updatedNote.appointment.id);
        if (appointmentIndex !== -1) {
            const noteIndex = appointments.value[appointmentIndex].notes.findIndex(note => note.id === updatedNote.id);
            if (noteIndex !== -1) {
                appointments.value[appointmentIndex].notes.splice(noteIndex, 1, updatedNote);
            }
        }
    }
    const DELETE_APPOINTMENT_NOTE = (noteId) => {
        const appointmentIndex = appointments.value.findIndex(appointment => appointment.notes.some(note => note.id === noteId));
        if (appointmentIndex !== -1) {
            appointments.value[appointmentIndex].notes = appointments.value[appointmentIndex].notes.filter(note => note.id !== noteId);
        }
    }

    const removeAppointmentFromTable = (appointmentId) => {
        const appointmentIndex = appointments.value.findIndex(appointment => appointment.id === appointmentId);
        if (appointmentIndex !== -1) {
            appointments.value.splice(appointmentIndex, 1);
        }
    }


    const fetchAppointments = async (params) => {
        SET_LOADING(true);
        const response = await apiv2.getAppointments(params);
        SET_APPOINTMENTS(response.data);
        SET_TOTAL_RECORDS(parseInt(response.headers['x-total-count']));
        SET_LOADING(false);
        return response.data;
    };
    const createAppointmentToAdverseEvent = async ({ adverseEventId, appointmentData }) => {
        const newAppointment = await apiv2.addAppointmentToAdverseEvent(adverseEventId, appointmentData);
        ADD_APPOINTMENT(newAppointment);
        return newAppointment;
    };
    const createAppointment = async ({ profileId, appointmentData }) => {
        const newAppointment = await apiv2.addAppointment(profileId, appointmentData);
        ADD_APPOINTMENT(newAppointment);
        return newAppointment;
    };
    const updateAppointment = async ({ appointmentId, appointmentData }) => {
        const appointmentPayload = { ...appointmentData, id: appointmentId };
        const updatedAppointment = await apiv2.updateAppointment(appointmentId, appointmentPayload);
        UPDATE_APPOINTMENT(updatedAppointment);
        return updatedAppointment;
    };
    const addNoteToAppointment = async ({ appointmentId, notePayload }) => {
        const newNote = await apiv2.addAppointmentNote(appointmentId, notePayload);
        ADD_APPOINTMENT_NOTE(newNote);
        return newNote;
    };
    const updateNoteOnAppointment = async ({ noteId, notePayload }) => {
        const updatedNote = await apiv2.updateAppointmentNote(noteId, notePayload);
        UPDATE_APPOINTMENT_NOTE(updatedNote);
        return updatedNote;
    };
    const deleteNoteOnAppointment = async ({ noteId }) => {
        await apiv2.deleteAppointmentNote(noteId);
        DELETE_APPOINTMENT_NOTE(noteId);
    };
    const fetchCareTeamBookingLinks = async (params) => {
        SET_LOADING_CARE_TEAM(true);
        const response = await apiv2.getAppointmentCareTeamBookingLinks(params);
        SET_CARE_TEAM_BOOKING_LINKS(response);
        SET_LOADING_CARE_TEAM(false);
        return response;
    };

    // [
    //     {
    //         "id": "97ff3cc5-5f99-4ec1-983d-042a037650f7",
    //         "userId": "01798dbb-7bc8-4a3f-95e5-08a5ead394d4",
    //         "name": "Dev US",
    //         "email": "deus01@gmail.com",
    //         "title": "30 Mins",
    //         "bookingLink": "google.com",
    //         "createdBy": "9000b390-80a9-4d8c-9ac7-2406f6e7cc76",
    //         "createdDate": "2024-04-17T04:23:40.832268Z",
    //         "lastModifiedBy": "9000b390-80a9-4d8c-9ac7-2406f6e7cc76",
    //         "lastModifiedDate": "2024-04-17T04:23:40.832268Z"
    //     },
    //     {
    //         "id": "6aad8823-3745-44ac-b9bc-dc8ef95575a1",
    //         "userId": "01798dbb-7bc8-4a3f-95e5-08a5ead394d4",
    //         "name": "Dev US",
    //         "email": "deus01@gmail.com",
    //         "title": "1 hour",
    //         "bookingLink": "google.ca",
    //         "createdBy": "9000b390-80a9-4d8c-9ac7-2406f6e7cc76",
    //         "createdDate": "2024-04-17T04:23:54.946664Z",
    //         "lastModifiedBy": "9000b390-80a9-4d8c-9ac7-2406f6e7cc76",
    //         "lastModifiedDate": "2024-04-17T04:23:54.946664Z"
    //     },
    //     {
    //         "id": "5bb0e654-6088-4eb6-ac92-8b8770c524d9",
    //         "userId": "0224e2e4-3939-4240-a70e-b29f4d528f9b",
    //         "name": "Jeremy Gale",
    //         "email": "truong.le+video@nuvoair.com",
    //         "title": "15 mins",
    //         "bookingLink": "yahoo.com",
    //         "createdBy": "9000b390-80a9-4d8c-9ac7-2406f6e7cc76",
    //         "createdDate": "2024-04-17T04:24:55.462610Z",
    //         "lastModifiedBy": "9000b390-80a9-4d8c-9ac7-2406f6e7cc76",
    //         "lastModifiedDate": "2024-04-17T04:24:55.462610Z"
    //     }
    // ]

    // [
    //     {
    //         userId: '01798dbb-7bc8-4a3f-95e5-08a5ead394d4',
    //         bookingLinks: [
    //             {
    //                 title: '30 Mins',
    //                 bookingLink: 'google.com'
    //             }
    //         ]
    //     }
    // ]
    const userMappedBookingLinks = computed(() => careTeamBookingLinks.value.reduce((acc, link) => {
        const user = acc.find(u => u.userId === link.userId) || { userId: link.userId, userIdPii: link.userIdPii, email: link.email, bookingLinks: [] };
        user.bookingLinks.push(link);
        if (!acc.some(u => u.userId === link.userId)) acc.push(user);
        return acc;
    }, []));

    const appointmentById = (id) => appointments.value.find(appointment => appointment.id === id);
    const lastUpdatedDateFromNow = computed(() => lastUpdatedDate.value ? lastUpdatedDate.value.fromNow() : null);
    const getNextAppointmentByPrincipalId = computed((principalId) => appointments.value
        .filter(app => app.principal.id === principalId)
        .sort((a, b) => moment(a.start).diff(moment(b.start)))
        .find(app => moment(app.start).isAfter(moment()))
    );

    return {
        loading,
        lastUpdatedDate,
        appointments,
        totalRecords,
        fetchAppointments,
        createAppointmentToAdverseEvent,
        createAppointment,
        updateAppointment,
        removeAppointmentFromTable,
        addNoteToAppointment,
        updateNoteOnAppointment,
        deleteNoteOnAppointment,
        appointmentById,
        lastUpdatedDateFromNow,
        getNextAppointmentByPrincipalId,
        fetchCareTeamBookingLinks,
        careTeamBookingLinks,
        userMappedBookingLinks
    };
}

