/**
 * Given a value (Date, string, or Number) convert that to a Date object.
 *
 * @param value A Date, date string, or Number
 * @returns Date
 */
import moment from "moment-timezone";

export const DEFAULT_DATE_FORMAT = 'll';
export const DEFAULT_TIME_FORMAT = 'LT';
export const DEFAULT_DATE_TIME_FORMAT = `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;
export const DEFAULT_TIMEZONE = moment.tz.guess();

export const DATE_RANGE_TYPE_WEEK = 'week';
export const DATE_RANGE_TYPE_MONTH = 'month';
export const DATE_RANGE_TYPE_QUARTER = 'quarter';
export const DATE_RANGE_TYPE_YEAR = 'year';

export function toDate (value) {
  if (!value) {
    throw Error('Please provide a proper date value, not this:', value);
  }

  if (value instanceof Date) {
    return value;
  } else if (typeof value === 'string') {
    return new Date(Date.parse(value));
  } else if (typeof value === 'number') {
    return new Date(value);
  }

  console.error('Cannot convert to a Date object:', value);
  throw Error('Cannot convert to Date');
}

function formatDateTimeMoment (dateOrString, format, timezone = DEFAULT_TIMEZONE) {
  if (!dateOrString) {
    return 'Invalid Date'
  }

  const m = moment(dateOrString);

  const toTZ = m.tz(timezone);
  return toTZ.format(format);
}

export function formatDate (dateOrString, format = DEFAULT_DATE_FORMAT, timezone = DEFAULT_TIMEZONE) {
  return formatDateTimeMoment(dateOrString, format, timezone);
}

export function formatTime (dateOrString, format = DEFAULT_TIME_FORMAT, timezone = DEFAULT_TIMEZONE) {
  return formatDateTimeMoment(dateOrString, format, timezone);
}

export function formatDateTime (dateOrString, format = DEFAULT_DATE_TIME_FORMAT, timezone = DEFAULT_TIMEZONE) {
  return formatDateTimeMoment(dateOrString, format, timezone);
}

export function toYYYY_MM_DD (value) {
  return moment(value).format('YYYY-MM-DD');
}

/**
 * Given a 'YYYY-MM-DD' date String, parse that and parse it to a UTC timestamp.
 *
 * @param yyyy_mm_dd
 * @returns {number}
 */
export function yyyy_mm_dd_toTimestamp (yyyy_mm_dd) {
  const [yyyy, mm, dd] = yyyy_mm_dd.split('-');

  return new Date(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd), 0, 0, 0, 0).getTime();
}

/**
 * Given a 'YYYY-MM-DD' date String, parse that and parse it to a Date.
 *
 * @param yyyy_mm_dd
 * @returns {number}
 */
export function YYYYmmddToDate (dateString) {
  const [y, m, d] = dateString.split('-');

  return new Date(parseInt(y), parseInt(m) - 1, parseInt(d));
}

/**
 * Given a date, convert that to YYYY_mm_dd
 *
 * @param date
 * @returns {string|undefined}
 */
export function dateTo_YYYY_mm_dd (date) {
  if (typeof date === 'string') {
    return date;
  }

  if (date) {
    const y = date.getFullYear();
    const mInt = date.getMonth() + 1;
    const m = mInt < 10 ? '0' + mInt : mInt.toString();
    const dInt = date.getDate();
    const d = dInt < 10 ? '0' + dInt : dInt.toString();
    const s = y + '-' + m + '-' + d;
    return s;
  }

  return undefined;
}

export function isValidDate (d) {
  return d instanceof Date && !isNaN(d);
}

/**
 * Find the number of days between d1 and d2
 *
 * @param d1
 * @param d2
 * @returns {number}
 */
export function daysBetween (d1, d2) {
  const timeDelta = Math.abs(d2.getTime() - d1.getTime());
  const dayLength = 1000 * 60 * 60 * 24;

  return Math.round(timeDelta / dayLength);
}

/**
 * Given a date, return a new date with the provided number of days added or removed from it, exactly.
 *
 * @param d1 The original date
 * @param days The number of days to add (or negative to subtract)
 * @returns {Date} A new date
 */
export function addDays (d1, days) {
  const d = new Date(d1.getTime());
  d.setDate(d.getDate() + days);

  // We have to do this hour shift because sometimes there are daylight savings time and this accounts
  // for that.
  if (days > 0) {
    d.setHours(d.getHours() - 1);
  } else {
    d.setHours(d.getHours() + 1);
  }

  return d;
}

/**
 * Get range in milliseconds for a given range type.
 *
 * @param dateRangeType
 * @returns {number}
 */
export function getMillisForRange (dateRangeType) {
  if (dateRangeType === DATE_RANGE_TYPE_WEEK) {
    return 7 * 24 * 60 * 60 * 1000;
  } else if (dateRangeType === DATE_RANGE_TYPE_MONTH) {
    return 30 * 24 * 60 * 60 * 1000;
  } else if (dateRangeType === DATE_RANGE_TYPE_QUARTER) {
    return 91 * 24 * 60 * 60 * 1000;
  } else if (dateRangeType === DATE_RANGE_TYPE_YEAR) {
    return 365 * 24 * 60 * 60 * 1000;
  }
}

/**
 * Returns if the provided date is today for the user
 * @param date
 * @returns {boolean}
 */
export function isToday(date) {
  const evaluatedDate = new Date(date);
  const todaysDate = new Date();
  return evaluatedDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0);
}

/**
 * Returns if the provided date is tomorrow or later for the user
 * @param date
 * @returns {boolean}
 */
 export function isAfterToday(date) {
  const evaluatedDate = new Date(date);
  const todaysDate = new Date();
  return evaluatedDate.setHours(0, 0, 0, 0) > todaysDate.setHours(0, 0, 0, 0);
}