<template>
  <SidebarContent :id="id">
    <template v-slot:sidebar-header>
      <div v-if="viewChannelList" class="flex align-items-center font-medium text-2xl">
        <span>Messages</span>
      </div>
      <div v-else class="flex align-items-center font-medium text-2xl">
        <Button icon="pi pi-angle-left" class="p-button-secondary p-button-text" @click="toggleView" />
        <span>Messages</span>
        <Button icon="pi pi-window-maximize" class="p-button-text" @click="maximize_clickHandler" />
      </div>
    </template>

    <template v-slot:sidebar-content>
      <ProgressSpinner v-if="loading" style="width: 50px; height: 50px" />
      <div v-else class="flex horizontal full-height overflow-hidden">
        <SmsSidebarMessageList v-if="isMaximized || viewChannelList" @openChannel="openChannel_clickHandler"
          :maximized="isMaximized" />
        <SmsSidebarMessageThread v-if="isMaximized || !viewChannelList" :principal="channelPrincipal"
          :maximized="isMaximized" />
      </div>
    </template>
  </SidebarContent>
</template>

<script>
import SidebarContent from "@/components/layout/sidebar/SidebarContent";
import SmsSidebarMessageList from "@/components/layout/sidebar/sidebar-panels/sms/SmsSidebarMessageList";
import SmsSidebarMessageThread from "@/components/layout/sidebar/sidebar-panels/sms/SmsSidebarMessageThread";
import { createNamespacedHelpers } from 'vuex';

const sidebarVuex = createNamespacedHelpers('sidebar');
const messagingVuex = createNamespacedHelpers('messaging');

export default {
  components: {
    SidebarContent,
    SmsSidebarMessageList,
    SmsSidebarMessageThread,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    payload: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      selectedChannel: null,
      viewChannelList: true,
      loading: false,
    }
  },
  async mounted () {
    if (this.payload?.principal) {
      this.viewChannelList = false;
    }
  },
  computed: {
    ...sidebarVuex.mapState(['maximizedId']),
    ...messagingVuex.mapState(['channels']),
    channelPrincipal () {
      return this.selectedChannel?.principal || this.payload?.principal || null;
    },
    isMaximized () {
      return this.id === this.maximizedId;
    },
  },
  methods: {
    ...sidebarVuex.mapActions(['maximizeSidebar']),
    openChannel_clickHandler (channel) {
      this.selectedChannel = channel;
      this.viewChannelList = false;
    },
    toggleView () {
      this.viewChannelList = !this.viewChannelList;
    },
    maximize_clickHandler () {
      this.maximizeSidebar(this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-main {
  padding: 20px 24px 24px;
}

.p-button-text {
  padding: 6px 0;
}
</style>
