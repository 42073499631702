import { ref, computed } from "@vue/composition-api";
import { purposeList, resultList } from "../components/communication/constants";
import moment from 'moment';

const initLog = {
  logDate: null,
  purpose: null,
  direction: 'NONE',
  medium: 'NONE',
  communicationResult: null,
  assignedPractitioner: null,
  subject: null,
  payload: null,
  duration: null,
  notes: null,
  noteText: '',
}

export function useActivityLogForm (originalLog, pastActivity, prefill) {
    const log = ref({...initLog});

    const isEditMode = computed(() => !!originalLog.value && !prefill.value);

    const disabledFields = computed(() => {
      const disabledFields = {duration: !pastActivity.value};
      const purposeDrivenDisabledFields = purposeList.find(p => p.key === log.value.purpose)?.disabledFields || [];
      purposeDrivenDisabledFields.forEach(key => {
        disabledFields[key] = true;
      });
      const resultDrivenDisabledFields = (resultList[log.value.medium] || []).find(r => r.key === log.value.communicationResult)?.disabledFields || [];
      resultDrivenDisabledFields.forEach(key => {
        disabledFields[key] = true;
      });
      return disabledFields;
    })

    const hiddenFields = computed(() => {
      return { 
        assignedPractitioner: pastActivity.value, 
      }
    })

    const mediumKey = computed(() => log.value.medium);
    const isSMS = computed(() => mediumKey.value === 'WRITTEN_SMS');

    const hasUniversallyRequiredFields = computed(() => log.value.purpose && mediumKey.value && log.value.logDate);
    
    const hasFutureActivityRequiredFields = computed(() => log.value.assignedPractitioner);
    
    const hasInProgressActivityRequiredFields = computed(() => log.value.direction && 
      log.value.communicationResult && (isSMS.value ? log.value.payload : log.value.subject))
    
    const hasPastActivityRequiredFields = computed(() => hasInProgressActivityRequiredFields && 
      (isSMS.value || log.value.duration))
    
    const isComplete = computed(() => {
      const isCompletedForm = 
        (hasUniversallyRequiredFields && (
            !pastActivity.value && hasFutureActivityRequiredFields.value || 
            log.value.communicationResult === 'IN_PROGRESS' && hasInProgressActivityRequiredFields.value ||
            hasPastActivityRequiredFields.value
          )
        )
      return !!isCompletedForm
    })

    const hasChanges = computed(() => {
      // Checks for differences in values between the original log and the edited log.
      const hasChangesToOriginalLog = originalLog.value && Object.entries(originalLog.value).some(([key, value]) => {
        if (disabledFields.value[key] || key === 'notes') {
          return false;
        }
        return log.value[key] && log.value[key] !== value
      });
      
      // Checks if the edited log has any additional fields not present in the original log.
      const hasAdditionalFields = log.value && Object.entries(log.value).some(([key, value]) => {
        if (disabledFields.value[key]) {
          return false;
        }
        return !!value && !originalLog.value[key];
      });
      return hasChangesToOriginalLog || hasAdditionalFields;
    });

    const changedLogFields = (newLog) => {
      const fields = {};
      Object.entries(newLog).forEach(([key, val]) => {
        if (originalLog.value[key] !== newLog[key]) {
          fields[key] = val;
        }
      });
      return fields;
    }

    const changedEncounterFields = (newEncounter) => {
      const fields = {};
      const originalEncounter = originalLog.value.encounter;
      if (!originalEncounter) { return newEncounter };
      Object.entries(newEncounter).forEach(([key, val]) => {
        if (originalEncounter[key] !== newEncounter[key]) {
          fields[key] = val;
        }
      });
      return fields;
    }

    // Mutations
    const SET_LOG = (newVal) => {
      log.value = newVal;
    }

    const UPDATE_LOG = (newValues) => {
      log.value = {
        ...log.value,
        ...newValues,
      }
    }

    const DELETE_LOG_PROPERTIES = (keysToDelete) => {
      keysToDelete.forEach(key => delete log.value[key]);
    }

    // Actions
    const resetForm = () => {
      SET_LOG({...initLog});
    }

    const replaceLog = (newLog) => {
      SET_LOG(newLog);
    }

    const initializeLogDate = () => {
      UPDATE_LOG({logDate: moment().toDate()});
    }

    const updateLogValues = (newLogValues) => {
      UPDATE_LOG(newLogValues);
    }

    const deleteLogProperties = (keysToDelete) => {
      DELETE_LOG_PROPERTIES(keysToDelete);
    }

    const logPayload = computed(() => {
      const {logDate, duration, ...newLog} = {...log.value}

      if (pastActivity.value) {
        newLog.sent = logDate;
      } else {
        newLog.startTime = logDate;
      }

      if (newLog.communicationResult && newLog.communicationResult !== 'IN_PROGRESS') {
        newLog.encounter = {
          ...newLog.encounter,
          durationInSeconds: duration * 60,
        }
      }

      if (isEditMode.value) {
        return newLog;
      }
      else {
        // * set a default value to PENDING
        if (!pastActivity.value) {
          newLog.status = 'PENDING'
        }
        if (prefill.value) {
          if (log.value.noteText) {
            newLog.notes = log.value.noteText
          }
        } else {
          if (log.value.noteText) {
            newLog.notes = [{ text: log.value.noteText }]
          }
        }
        if (newLog.notes === null) {
          delete newLog.notes;
        }
        return newLog;
      }
    })

    const patchLogPayload = computed(() => {
      const payload = {id: log.value.id, ...changedLogFields(logPayload.value)};
      if (Object.keys(patchEncounterPayload.value).length > 1) {
        payload.encounter = patchEncounterPayload.value;
      } else if (payload.encounter) {
        delete payload.encounter;
      }
      return payload;
    })

    const patchEncounterPayload = computed(() => {
      return {id: log.value.encounter?.id, ...changedEncounterFields(logPayload.value.encounter)};
    })
    
    // Getters
    

    return {
        log,
        isEditMode,
        disabledFields,
        hiddenFields,
        isSMS,
        isComplete,
        hasChanges,
        logPayload,
        patchLogPayload,
        resetForm,
        replaceLog,
        initializeLogDate,
        updateLogValues,
        deleteLogProperties,
    };
}

