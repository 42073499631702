import {ASTHMA_REPORT_ACQ_EMPTY, ASTHMA_REPORT_EMPTY_ACQ_STATE} from "@/AsthmaReportConstants";

export function asthmaReportSerialize(asthmaReport) {
  const { id, siteId, name, description = '', profileId, dateStart, dateEnd, comments, attachedReports, referringClinician, state, acq } = asthmaReport;
  
  return {
    id,
    name,
    description,
    profile_id : profileId,
    start_date: dateStart.toISOString(),
    end_date: dateEnd.toISOString(),
    site_id: siteId,
    type: 'asthma',
    state,
    details: {
      referringClinician: {
        name: referringClinician.name
      },
      comments: {
        insights: comments.insights || '',
        onboarding: comments.onboarding || '',
        spirometry_coaching: comments.spirometryCoaching || '',
        spirometry_notes: comments.spirometryNotes || '',
      },
      attached_reports: {
        spirometry_sessions: (attachedReports.spirometrySessions || []).map(ss => ({
          id: ss.id,
          created_at: ss.created_at,
          comments: {
            main: ss.comments.main
          }
        })),
        prepost: (attachedReports.preposts || []).map(prepost => ({
          pre: {
            id: prepost.pre.id,
            created_at: prepost.pre.created_at
          },
          post: {
            id: prepost.post.id,
            created_at: prepost.post.created_at
          },
          comments: {
            main: prepost.comments.main,
            tolerance: prepost.comments.tolerance,
            symptoms: prepost.comments.symptoms,
            saba_used: prepost.comments.sabaUsed
          }
        }))
      },
      acq: acq ? acq : ASTHMA_REPORT_EMPTY_ACQ_STATE
    }
  }
}

/**
 * Patch on the backend is different than Post.
 *
 * @param asthmaReport
 * @returns {{end_date: *, name, description: string, details: {attached_reports: {spirometry_sessions: {comments: *, id: *}[], prepost: {pre: *, comments: {symptoms: string|*, saba_used: string|*, main: *, tolerance: number|number|string|*}, post: *}[]}, comments: {spirometry_notes: string, insights: string, spirometry_coaching: string, onboarding: string}}, state, start_date: *}}
 */
export function asthmaReportPatchSerialize(report) {
  const { name, state, description = '', dateStart, dateEnd, comments, attachedReports, referringClinician, acq } = report;

  return {
    name,
    description,
    start_date: dateStart.toISOString(),
    end_date: dateEnd.toISOString(),
    state,
    details: {
      referringClinician: {
        name: referringClinician.name
      },
      comments: {
        insights: comments.insights || '',
        onboarding: comments.onboarding || '',
        spirometry_coaching: comments.spirometryCoaching || '',
        spirometry_notes: comments.spirometryNotes || '',
      },
      attached_reports: {
        spirometry_sessions: (attachedReports.spirometrySessions || []).map(ss => ({
          id: ss.id,
          created_at: ss.created_at,
          comments: {
            main: ss.comments.main
          }
        })),
        prepost: (attachedReports.preposts || []).map(prepost => ({
          pre: {
            id: prepost.pre.id,
            created_at: prepost.pre.created_at
          },
          post: {
            id: prepost.post.id,
            created_at: prepost.post.created_at
          },
          comments: {
            main: prepost.comments.main,
            tolerance: prepost.comments.tolerance,
            symptoms: prepost.comments.symptoms,
            saba_used: prepost.comments.sabaUsed
          }
        }))
      },
      acq: acq ? {...ASTHMA_REPORT_EMPTY_ACQ_STATE, ...acq} : ASTHMA_REPORT_EMPTY_ACQ_STATE
    }
  }
}

export function asthmaReportDeserialize(asthmaReportRaw) {
  const acqData = asthmaReportRaw.details.acq ? asthmaReportRaw.details.acq : ASTHMA_REPORT_EMPTY_ACQ_STATE;

  acqData.dates = (acqData.dates || ASTHMA_REPORT_ACQ_EMPTY).map(isoDS => {
    return isoDS ? new Date(isoDS) : undefined
  });

  return asthmaReportRaw ? {
    id : asthmaReportRaw.id,
    name : asthmaReportRaw.name,
    description: asthmaReportRaw.description,
    type : 'Asthma',
    state: asthmaReportRaw.state,
    profileId : asthmaReportRaw.profile_id,
    dateStart: new Date(asthmaReportRaw.start_date),
    dateEnd: new Date(asthmaReportRaw.end_date),
    createdAt: new Date(asthmaReportRaw.created_at),
    createdBy: asthmaReportRaw.createdBy ? {
      id : asthmaReportRaw.createdBy.id,
      firstName : asthmaReportRaw.createdBy.first_name,
      lastName : asthmaReportRaw.createdBy.last_name
    } : undefined,
    updatedAt: new Date(asthmaReportRaw.updatedAt),
    updatedBy: asthmaReportRaw.updatedBy ? {
      id : asthmaReportRaw.updatedBy.id,
      firstName : asthmaReportRaw.updatedBy.first_name,
      lastName : asthmaReportRaw.updatedBy.last_name
    } : undefined,
    comments: {
      insights: asthmaReportRaw.details.comments.insights,
      onboarding: asthmaReportRaw.details.comments.onboarding,
      spirometryCoaching: asthmaReportRaw.details.comments.spirometry_coaching,
      spirometryNotes: asthmaReportRaw.details.comments.spirometry_notes
    },
    referringClinician: {
      name: asthmaReportRaw.details.referringClinician ? asthmaReportRaw.details.referringClinician.name : ''
    },
    attachedReports: {
      spirometrySessions: asthmaReportRaw.details.attached_reports?.spirometry_sessions.map(ss => ({
        id: ss.id,
        created_at: ss.created_at,
        comments: {
          main: ss.comments.main
        }
      })),
      preposts: asthmaReportRaw.details.attached_reports?.prepost.map(ss => ({
        pre: {
          id: ss.pre.id,
          created_at: ss.pre.created_at
        },
        post: {
          id: ss.post.id,
          created_at: ss.post.created_at
        },
        comments: {
          main: ss.comments.main,
          tolerance: ss.comments.tolerance,
          symptoms: ss.comments.symptoms,
          sabaUsed: ss.comments.saba_used
        }
      }))
    },
    // When deserializing we default to the empty state for old reports that were saved without ACQ
    acq: asthmaReportRaw.details.acq ? asthmaReportRaw.details.acq : ASTHMA_REPORT_EMPTY_ACQ_STATE
  } : undefined;
}
