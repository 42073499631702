import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
} from '../utils/dateUtil';
//----------------------------------------------------------------------------
// State
//----------------------------------------------------------------------------
export const state = () => ({
  weightUnit: 'kg',
  heightUnit: 'cm',
  temperatureUnit: 'celsius',
  dateFormat: DEFAULT_DATE_FORMAT,
  dateTimeFormat: DEFAULT_DATE_TIME_FORMAT
});

//----------------------------------------------------------------------------
// Mutations
//----------------------------------------------------------------------------
export const mutations = {
  setWeightUnit: function(state, value) {
    state.weightUnit = ['kg', 'kgs', 'kilograms'].indexOf(value) > -1 ? 'kg' : 'lbs';
  },
  setHeightUnit: function(state, value) {
    state.heightUnit = ['cm', 'centimeters', 'cms', 'm', 'meters'].indexOf(value) > -1 ? 'cm' : 'in';
  },
  setTemperatureUnit: function(state, value) {
    state.temperatureUnit = value;
  },
  setDateFormat: function(state, value) {
    state.dateFormat = value;
  },
  setDateTimeFormat: function(state, value) {
    state.dateTimeFormat = value;
  }
};
