<template>
  <div class="patient-create-overlay flex flex-column align-items-center overflow-visible">
    <div v-if="loadingInternal.value || loadingRegistry.value" class="flex flex-column justify-content-center">
      <ProgressSpinner class="mt-4 mb-3" style="width: 50px; height: 50px" />
      <span v-if="loadingInternal.value" class="text-xl">Searching within Nuvoair's known practitioners...</span>
      <span v-if="loadingRegistry.value" class="text-center text-xl">This is a new practitioner for NuvoAir.</span>
      <span v-if="loadingRegistry.value" class="text-center text-xl">Searching Government Registry for matches...</span>
    </div>
    <div v-else-if="error.value" class="text-center text-red-400 font-medium mt-4">{{error.value}}</div>
    <div v-else-if="matches.value !== null" class="patient-create-overlay flex overflow-auto">
      <div class="flex flex-column overflow-y-hidden">
        <span class="mb-3 text-xl">Results ({{totalRecords.value.toString() || ''}})</span>
        <div v-if="!matches.value.length" class="flex flex-column">
          <span class="mb-1 text-xl">Sorry there are no matching results</span>
          <span class="mb-1 text-base">Please try refiltering with new search criteria</span>
        </div>
        <div class="flex flex-column overflow-y-auto profile-results">
          <span v-if="matchesWithinSelectedSite.value.length" class="mb-1 text-base">Practitioners associated with <b>{{ this.selectedSite.value.long_name }}</b>:</span>
          <div :key="match.npi" v-for="match in matchesWithinSelectedSite.value" @click="practitioner_clickHandler(match)"
            class="profile-result p-3 my-2 ml-1 mr-3 flex flex-row justify-content-between border-round-2xl shadow-1">
            <div class="flex flex-column">
              <span class="font-bold text-2xl">{{match.credential || ''}}</span>
              <span class="font-semibold text-xl text-primary">{{displayPractitionerName(match)}}</span>
              <span class="font-base text-lg">NPI: {{match.npi || '--'}}</span>
            </div>
          </div>
          <span v-if="matchesOutsideSelectedSite.value.length" class="mt-2 mb-1 text-base">Practitioners not in the Group/Practice you selected:</span>
          <span v-if="matchesOutsideSelectedSite.value.length" class="mb-1 text-sm">Click the + icon to add a practitioner to the group <b>{{ this.selectedSite.value.long_name }}</b></span>
          <div :key="match.npi" v-for="match in matchesOutsideSelectedSite.value"
            class="profile-result inaccessible p-3 my-2 ml-1 mr-3 flex flex-row justify-content-between border-round-2xl shadow-1">
            <div class="flex flex-column">
              <span class="font-bold text-2xl">{{match.credential || ''}}</span>
              <span class="font-semibold text-xl text-primary">{{displayPractitionerName(match)}}</span>
              <span class="font-base text-lg">NPI: {{match.npi || '--'}}</span>
            </div>
            <div v-if="selectedSite.value" class="flex flex-row flex-grow-0 align-items-center">
              <Button class="p-button-text p-button mr-2" icon="pi pi-plus"
              v-tooltip="'Add To Group'" @click="addPractitioner_clickHandler(match)" />
            </div>
          </div>
          <Divider />
          <div v-if="!showingNpiResults.value" class="flex flex-column mb-1">
            <span class="text-base">Don't see the practitioner you are looking for?</span>
            <Button class="mt-3 ml-2 max-w-max" type="button" icon="pi pi-search" label="Search NPI Registry" @click="npiSearch_clickHandler" />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-full">
      <span class="text-2xl">Step 1: Provide Practice Info</span>
      <div class="mt-5 flex flex-column">
        <span class="mb-1 text-lg">Select the Group/Practice for this Patient:</span>
      </div>
      <AutoComplete v-model="selectedSite.value" class="w-full" :dropdown="true" dropdownMode="current" :suggestions="siteOptions.value" 
        field="full_name" @item-select="site_selectHandler" @complete="site_searchHandler" forceSelection />
      <Divider />
      <div class="flex flex-column">
        <span class="mb-1 text-lg">Search for the referring practitioner using any or all fields.</span>
        <span class="mb-1 text-sm">Any text fields left empty will not be applied to the search.</span>
        <span class="-mb-3 mt-2 text-sm align-self-end">Apply Flexible Match:</span>
      </div>
      <PatientSearchInput fieldKey="lastName" displayName="Practitioner Last Name" :isFlexibleMatchCapable="isFlexibleMatchCompatibleField.value('lastName')"
        :inputValue="getFetchParamValue.value('lastName','value')" @inputChange="updateFetchParams"
        :checkboxValue="getCheckboxValue('lastName')"  @checkboxSelected="updateFetchParams" />
      <PatientSearchInput fieldKey="firstName" displayName="Practitioner First Name" :isFlexibleMatchCapable="isFlexibleMatchCompatibleField.value('firstName')"
        :inputValue="getFetchParamValue.value('firstName','value')" @inputChange="updateFetchParams"
        :checkboxValue="getCheckboxValue('firstName')"  @checkboxSelected="updateFetchParams" />
      <PatientSearchInput fieldKey="npi" displayName="Practitioner NPI" :isFlexibleMatchCapable="isFlexibleMatchCompatibleField.value('npi')"
      :inputValue="getFetchParamValue.value('npi','value')" @inputChange="updateFetchParams"
      :checkboxValue="getCheckboxValue('npi')"  @checkboxSelected="updateFetchParams" />
    </div>
    <div class="flex flex-row justify-content-between w-full">
      <Button v-if="!loadingInternal.value && !loadingRegistry.value && matches.value" class="mt-3 ml-2 p-button-text" 
        type="button" icon="pi pi-chevron-left" label="Refilter" @click="navigateBack_clickHandler" />
      <Button v-if="!error.value" class="mt-3 ml-2 p-button-text" type="button" icon="pi pi-refresh" label="Clear" @click="clearFilters_clickHandler" />
      <Button v-if="!loadingInternal.value && !loadingRegistry.value && !matches.value" class="mt-3 ml-2" type="button" 
        icon="pi pi-search" label="Search" :disabled="!canSearch" @click="search_clickHandler" />
    </div>
  </div>
</template>

<script>
import PatientSearchInput from './PatientSearchInput';
import { usePractitionerSearch } from '@/composables/practitionerSearch';
import { createNamespacedHelpers } from 'vuex';
const portalVuex = createNamespacedHelpers('portal');

export default {
    components: {
      PatientSearchInput,
    },
    data () {
      const {
        loadingInternal, loadingRegistry, loadingSites, matches, fetchParams, // State
        showingNpiResults, error, totalRecords, selectedSite, siteOptions,
        getFetchParamValue, isFlexibleMatchCompatibleField, matchesWithinSelectedSite, matchesOutsideSelectedSite, // Getters
        searchPractitioners, searchNpiRegistry, searchSites, assignPractitionerToSite, createPractitioner,  // Actions
        updateFetchParams, resetFetchParams, setSiteOptions, setMatches, fetchSite, setLoadingInternal, setloadingRegistry, setError
      } = usePractitionerSearch();

      return {
        loadingInternal,
        loadingRegistry,
        loadingSites,
        matches,
        fetchParams,
        showingNpiResults,
        error,
        totalRecords,
        selectedSite,
        siteOptions,
        getFetchParamValue,
        isFlexibleMatchCompatibleField,
        matchesWithinSelectedSite,
        matchesOutsideSelectedSite,
        searchPractitioners,
        searchNpiRegistry,
        assignPractitionerToSite,
        createPractitioner,
        updateFetchParams,
        resetFetchParams,
        setSiteOptions,
        fetchSite,
        setLoadingInternal,
        setMatches,
        setloadingRegistry,
        setError,
        searchSites,
      }
    },
    mounted() {
      this.setSiteOptions(this.sitesSortedByFullName);
    },
    computed: {
      ...portalVuex.mapGetters(['sitesSortedByFullName']),
      canSearch() {
        return this.selectedSite.value?.id && this.fetchParams.value?.filters && Object.entries(this.fetchParams.value.filters).some(([key, val]) => {
          return !!this.fetchParams.value.filters[key].value;
        });
      },
    },
    methods: {
      displayPractitionerName(practitioner) {
        const practitionerFirstName = practitioner.name?.firstName || practitioner.authorizedOfficialFirstName || '';
        const practitionerLastName = practitioner.name?.lastName || practitioner.authorizedOfficialLastName || '';
        return practitionerFirstName || practitionerLastName ? `${practitionerFirstName} ${practitionerLastName}` : `[Unknown Practitioner]`;
      },
      getCheckboxValue(filterKey) {
        return this.getFetchParamValue.value(filterKey, 'matchMode') === 'equals' ? false : true;
      },
      search_clickHandler() {
        this.searchPractitioners();
      },
      npiSearch_clickHandler() {
        this.searchNpiRegistry();
      },
      site_searchHandler({query}) {
        this.searchSites(query, this.sitesSortedByFullName);
      },
      site_selectHandler({value: site}) {
        this.fetchSite(site);
      },
      clearFilters_clickHandler() {
        this.resetFetchParams();
        this.setMatches(null);
        this.setLoadingInternal(false);
        this.setloadingRegistry(false);
        this.setError(null);
      },
      navigateBack_clickHandler() {
        this.setMatches(null);
        this.setError(null);
      },
      practitioner_clickHandler(practitioner) {
        this.$emit('practitionerSelected', {practitioner, site: this.selectedSite.value});
      },
      addPractitioner_clickHandler(practitioner) {
        if (this.showingNpiResults.value) {
          this.createPractitioner(practitioner);
        } else {
          this.assignPractitionerToSite(practitioner);
        }
      },
    },
}
</script>

<style lang="scss" scoped>
.patient-create-overlay {
  min-width: 382px;
  max-width: 382px;
  width: 100%;
  max-height: 75vh;
  ::v-deep .p-divider {
    border-top: 1px solid #DEE2E6;
  }
  .profile-result {
    border: 1px solid var(--surface-300);
    &:hover {
      background-color: #E9F4FF;
      border-color: #0073E6;
      transform: scale(1.01);
      cursor: pointer;
    }
    &.inaccessible:hover {
      background-color: transparent;
      transform: none;
      cursor: default;
    }
  }
}
</style>