//--------------------------------------------------------------------------------------------------------
// Mutations
//--------------------------------------------------------------------------------------------------------
import {addDays, daysBetween} from "@/utils/dateUtil";
import {
  DATE_RANGE_NAMES,
  TIMELINE_CLEAR_DATA_BY_SOURCE_TAG,
  TIMELINE_FORCE_REFRESH_BY_SOURCE_TAG,
  TIMELINE_SET_CURSOR_DATE,
  TIMELINE_SET_DATA_BY_SOURCE_TAG, TIMELINE_SET_DATE_RANGE, TIMELINE_SET_LOADING_BY_SOURCE_TAG,
  TIMELINE_SET_PROFILE, TIMELINE_SET_SHOW_GRID_BY_SOURCE_TAG,
  TIMELINE_SET_SITE_PROFILE
} from "@/components/timeline/TimelineConstants";
import assignDeep from "assign-deep";
import deepEqual from "deep-equal";
import {
  ASTHMA_REPORT_ATTACH_PRE_POST_REPORT,
  ASTHMA_REPORT_ATTACH_SPIROMETRY_REPORTS,
  ASTHMA_REPORT_RESET,
  ASTHMA_REPORT_SELECT_SPIROMETRY_SESSIONS,
  ASTHMA_REPORT_SET_ACQ_DATE,
  ASTHMA_REPORT_SET_ACQ_SCORE,
  ASTHMA_REPORT_SET_DATE_RANGE_OVERLAY_OPEN,
  ASTHMA_REPORT_SET_FIX_TO,
  ASTHMA_REPORT_SET_STATE,
  ASTHMA_REPORT_SET_STORED_FILTERS,
} from "@/AsthmaReportConstants";
import {initialState, ASTHMA_REPORT_EMPTY_STATE} from "@/store/asthmaReport/state";

const timeline_setDateRange = function(state, { startDate, endDate }) {
  if (state.dateRangeFixDays > 0) {
    const twentyFourHours = 24 * 60 * 60 * 1000;
    const startChanged = Math.abs(startDate.getTime() - state.filters.startDate.getTime()) >= twentyFourHours;
    const endChanged = Math.abs(endDate.getTime() - state.filters.endDate.getTime()) >= twentyFourHours;

    if (startChanged) {
      startDate.setHours(0, 0, 0, 0);
      endDate = addDays(startDate, state.dateRangeFixDays);
    } else if (endChanged) {
      endDate.setHours(23, 59, 59, 999);
      startDate = addDays(endDate, -state.dateRangeFixDays);
    } else {
      // Neither one has changed, so just make sure there are only N days between
      const d = daysBetween(startDate, endDate);

      if (d !== state.dateRangeFixDays) {
        startDate.setHours(0, 0, 0, 0);
        endDate = addDays(startDate, state.dateRangeFixDays);
      }
    }

    if (startDate < new Date(state.profile.created_at)) {
      startDate = new Date(state.profile.created_at);
      startDate.setHours(0, 0, 0, 0);
      endDate = addDays(startDate, state.dateRangeFixDays);
    }

    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);

    if (endDate > endOfToday) {
      endDate = endOfToday;
      startDate = addDays(endDate, -state.dateRangeFixDays);
    }
  }

  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);

  state.filters = {
    rangeName: DATE_RANGE_NAMES.CUSTOM, // We only allow custom for the Asthma Timeline
    startDate,
    endDate
  };

  validate(state);
  compareChanges(state);
}

function validate(state) {
  // We automate report name if there is no report name OR
  // report name matches 'Asthma * Days Report'
  if (state.report.name === undefined || (state.report.name.startsWith('Asthma ') && state.report.name.endsWith(' Days Report'))) {
    if (state.dateRangeFixDays) {
      state.report.name = `Asthma ${state.dateRangeFixDays} Days Report`;
    } else {
      const days = daysBetween(state.filters.startDate, state.filters.endDate);

      state.report.name = `Asthma ${days} Days Report`;
    }
  }
}

function compareChanges(state) {
  let isChanged = false;
  let initialReport = state.fetchedReport ? state.fetchedReport : ASTHMA_REPORT_EMPTY_STATE;
  let initialFilters = state.storedFilters ? state.storedFilters : undefined;
  for (let key in state.report) {
    if (!deepEqual(state.report[key], initialReport[key])) {
      if(key != 'hasChanges') {
        isChanged = true;
      };
    };
  };
  if (!isChanged && !deepEqual(state.filters, initialFilters)) {
    isChanged = true;
  };
  state.hasChanges = isChanged;
};

export default {
  [ASTHMA_REPORT_RESET]: function(state) {
    const is = initialState();
    for (let key in is) {
      state[key] = is[key];
    }

    validate(state);
  },
  [ASTHMA_REPORT_SET_FIX_TO]: function(state, fixToDays) {
    state.dateRangeFixDays = fixToDays;

    if (state.dateRangeFixDays) {
      timeline_setDateRange(state, {
        startDate: state.filters.startDate,
        endDate: state.filters.endDate
      });
    }

    validate(state);
  },
  [ASTHMA_REPORT_SET_STATE]: function(state, newState) {
    for (let key in newState) {
      if (state.hasOwnProperty(key) && newState.hasOwnProperty(key)) {
        state[key] = assignDeep(state[key], newState[key]);
      }
    }
    validate(state);
    compareChanges(state);
  },
  [TIMELINE_SET_PROFILE]: function(state, profile) {
    state.profile = profile;
  },
  [TIMELINE_SET_SITE_PROFILE]: function(state, siteProfile) {
    state.siteProfile = siteProfile;
  },
  [TIMELINE_SET_CURSOR_DATE]: function(state, cursorDate) {
    state.cursorDate = cursorDate;
  },
  [TIMELINE_CLEAR_DATA_BY_SOURCE_TAG]: function (state, sourceTag) {
    state.timelineSourcesByTag[sourceTag].data = [];
  },
  [TIMELINE_FORCE_REFRESH_BY_SOURCE_TAG]: function (state, sourceTag) {
    // lol
    state.timelineSourcesByTag[sourceTag].forceRefreshKey++;
  },
  [TIMELINE_SET_DATA_BY_SOURCE_TAG]: function (state, { sourceTag, data }) {
    state.timelineSourcesByTag[sourceTag].loading = false;
    state.timelineSourcesByTag[sourceTag].data = data;
  },
  [TIMELINE_SET_LOADING_BY_SOURCE_TAG]: function (state, sourceTag) {
    state.timelineSourcesByTag[sourceTag].loading = true;
  },
  [TIMELINE_SET_DATE_RANGE]: timeline_setDateRange,
  [ASTHMA_REPORT_SET_DATE_RANGE_OVERLAY_OPEN]: function (state, open) {
    state.dateRangeOverlayOpen = open;
  },
  [ASTHMA_REPORT_SELECT_SPIROMETRY_SESSIONS]: function (state, sessions) {
    state.selectedSpirometrySessions = sessions;
  },
  [TIMELINE_SET_SHOW_GRID_BY_SOURCE_TAG]: function (state, { sourceTag, showGrid }) {
    state.timelineSourcesByTag[sourceTag].showGrid = showGrid;
  },
  [ASTHMA_REPORT_ATTACH_SPIROMETRY_REPORTS]: function (state, spirometrySessions) {
    // push non-duplicates
    const sessionIds = state.report.attachedReports.spirometrySessions.map(s => s.id);
    const sessions = [...state.report.attachedReports.spirometrySessions];
    spirometrySessions.forEach(sess => {
      if (!sessionIds.includes(sess.id)) {
        sessions.push(sess);
      }
    });
    state.report.attachedReports.spirometrySessions = sessions;
    compareChanges(state);
  },
  [ASTHMA_REPORT_ATTACH_PRE_POST_REPORT]: function (state, prepost) {
    const exists = state.report.attachedReports.preposts.find(pp => pp.pre.id === prepost.pre.id && pp.post.id === prepost.post.id);

    if (!exists) {
      state.report.attachedReports.preposts = [...state.report.attachedReports.preposts, prepost];
    }
    compareChanges(state);
  },
  [ASTHMA_REPORT_SET_ACQ_SCORE]: function (state, { questionField, timePeriodIndex, score }) {
    state.report.acq[questionField].scores[timePeriodIndex] = score;
    compareChanges(state);
  },
  [ASTHMA_REPORT_SET_ACQ_DATE]: function (state, { timePeriodIndex, date }) {
    state.report.acq.dates[timePeriodIndex] = date;
    compareChanges(state);
  },
  [ASTHMA_REPORT_SET_STORED_FILTERS]: function (state, newState) {
    const newStoredFilters = {...newState.filters};
    state.storedFilters = newStoredFilters;
    compareChanges(state);
  }
};