<template>
  <ProgressSpinner class="mt-4" style="width: 50px; height: 50px" v-if="loading" />
  <div v-else :class="`flex vertical full-height overflow-hidden ${maximized ? 'sms-channel pa-6 pt-5 pr-0' : ''}`">
    <div
      :class="`flex align-items-center flex-initial text-3xl max-w-full ${maximized ? 'sms-channel-header flex-row-reverse justify-content-end' : 'mt-3'}`">
      <span class="mr-3">{{ patientFullName || 'Unknown' }}</span>
      <img class="profile-details-avatar mr-3" src="@/assets/img/profile.png" alt="">
    </div>
    <span :class="`${this.isManagedByMe ? 'user-managed' : ''}`">{{ channelOwnerText }}</span>
    
    <InlineMessage v-if="error" class="flex flex-grow-0 justify-content-start align-items-center mt-3" severity="error">{{error.message || 'SMS service has encountered an error'}}</InlineMessage>
    
    <div class="sms-thread-container">
      <TabView>
        <TabPanel header="SMS">
          <div class="flex flex-column overflow-hidden">
            <div class="phi-warning">
              <i class="pi pi-info-circle"></i>
              <span>To safeguard patient privacy, do not use SMS for sharing PHI.</span>
            </div>
            <div class="sms-message-thread">
              <div class="flex flex-column flex-grow-0" v-for="(item, index) in threadItems" :key="index">
                <span v-if="item.type === 'separator'" class="start-message">{{ item.text }}</span>
                <span v-else-if="item.type === 'loading'" class="flex flex-row mr-3 mb-3 align-self-end">
                  <ProgressSpinner style="width: 16px; height: 16px" />
                </span>
                <SmsSidebarMessageThreadItem v-else :channel="channel" :message="item" />
              </div>
              <span class="start-message">If you think you may be experiencing a life threatening emergency.
                We recommend you immediately call 911 for medical assistance, or go to a nearby emergency room.</span>
              <span class="start-message">Please allow 1 business day for our team to get back to you.</span>
              <span class="start-message">Start of Conversation</span>
            </div>
            <div class="sms-submission">
              <Textarea v-model="draftMessage" :autoResize="true" placeholder="Message" rows="1" cols="30" />
              {{ sendAs.toUpperCase() }}
              <SplitButton :class="`send-button p-button p-button-sm pa-0 ${!draftMessage ? 'disabled' : ''}`"
                label="Send" icon="pi pi-send" @click="sms_submitHandler" :model="sendAsOptions"
                buttonClass="dropdown-button" :disabled="!draftMessage" />
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>
  
<script>

import moment from 'moment';
import { setupScrollDetection } from "@/helpers";
import SmsSidebarMessageThreadItem from "@/components/layout/sidebar/sidebar-panels/sms/SmsSidebarMessageThreadItem";
import { createNamespacedHelpers } from 'vuex';
const messagingVuex = createNamespacedHelpers('messaging');
const portalVuex = createNamespacedHelpers('portal');
const sidebarVuex = createNamespacedHelpers('sidebar');

export default {
  components: {
    SmsSidebarMessageThreadItem,
  },
  props: {
    principal: {
      type: String,
      required: true,
    },
    maximized: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      channel: null,
      profile: null,
      draftMessage: '',
      sendAs: 'SMS',
      loading: true,
      sendingMessage: false,
      sendAsOptions: [
        {
          label: 'SMS',
          command: () => {
            this.sendAs = 'SMS';
          },
        },
      ],
      interval: null,
    }
  },
  async mounted () {
    this.loadChannel();
    this.setupSMSAutoRefresh();
  },
  destroyed () {
    this.tearDownAutoRefresh();
  },
  computed: {
    ...messagingVuex.mapState(['channels', 'error']),
    ...messagingVuex.mapGetters(['getMessagesByChannelId']),
    ...sidebarVuex.mapState(['expanded']),
    ...portalVuex.mapGetters(['userId']),
    messages () {
      return this.getMessagesByChannelId(this.channel?.id) || [];
    },
    threadItems () {
      const items = this.addDateSeparators(this.messages);
      if (this.sendingMessage) {
        items.unshift({
          type: 'loading',
          text: 'Sending...',
        })
      }
      return items;
    },
    isManagedByMe () {
      return this.channel?.owner === this.userId;
    },
    patientFullName () {
      return this.channel?.patientFullName || (this.profile ? (this.profile.firstName || "") + " " + (this.profile.lastName || "") : "");
    },
    channelOwnerText () {
      if (!this.channel?.owner) {
        return 'Managed by: No one';
      } else if (this.channel?.owner !== this.userId) {
        return `Managed by: ${this.channel?.ownerPii?.firstName || ''} ${this.channel?.ownerPii?.lastName || ''}`;
      } else {
        return 'Managed by you'
      }
    },
  },
  methods: {
    ...messagingVuex.mapActions(['sendMessage', 'fetchChannels', 'fetchLatestMessagesForChannel', 'fetchChannelByPrincipal']),
    async loadChannel () {
      if (!this.principal) {
        // ? Where is this being caught?
        throw new Error('A principal is required to load SMS thread');
      }
      this.loading = true;
      this.channel = await this.fetchChannelByPrincipal(this.principal);
      if (this.channel) {
        await this.fetchLatestMessagesForChannel({ channelId: this.channel.id });
      } else {
        await this.loadProfile(); // If no channel exists yet for principal, fetch patient info (i.e. name)
      }
      this.loading = false;
      setTimeout(() => {
        setupScrollDetection('.sms-message-thread', 'show-scrollbar');
      }, 200);
    },
    async loadProfile () {
      const response = await this.$apiv2.getProfileById(this.principal);
      this.profile = response?.data || null;
    },
    async sms_submitHandler () {
      this.sendingMessage = true;
      const sentMessage = await this.sendMessage({
        principal: this.principal,
        message: this.draftMessage,
      });
      if (sentMessage) {
        this.draftMessage = '';
        this.channel = sentMessage.channel;
      }
      this.sendingMessage = false;
    },
    addDateSeparators (sortedMessageList) {
      // Assumes it is provided a list of messages sorted from newest to oldest.
      let startOfNextTimePeriod = null;
      return sortedMessageList.reduce((acc, msg, ix) => {
        const msgCreatedDate = moment(msg.createdDate);
        if (this.shouldStartNewTimePeriod(msgCreatedDate, startOfNextTimePeriod)) {
          if (startOfNextTimePeriod) {
            acc.push({
              type: 'separator',
              text: startOfNextTimePeriod.calendar(),
            });
          }
          startOfNextTimePeriod = msgCreatedDate;
        }
        acc.push(msg);
        if (ix === sortedMessageList.length - 1) {
          acc.push({
            type: 'separator',
            text: moment(msgCreatedDate).calendar(),
          });
        }
        return acc;
      }, []);
    },
    shouldStartNewTimePeriod (date1, date2) {
      return !date1 || !date2 || date2.diff(date1, 'hours') >= 1;
    },
    setupSMSAutoRefresh () {
      this.interval = setInterval(() => {
        if (!this.channel?.id || !this.expanded) { return }
        this.fetchLatestMessagesForChannel(
          {
            channelId: this.channel.id,
          }
        );
      }, 5000);
    },
    tearDownAutoRefresh () {
      clearInterval(this.interval);
    },
  },
  watch: {
    principal: {
      //? why is this async?
      handler: async function () {
        this.loadChannel();
      },
    },
    channels (updatedChannels) {
      // Keeps the channel data updated
      if (!this.channel?.id) { return };
      const updatedChannel = updatedChannels.find(channel => channel.id === this.channel.id);
      if (updatedChannel) {
        this.channel = updatedChannel;
      };
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .p-tabview {
  .p-tabview-panels {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    padding: 0;
  }
}

.p-tabview,
.p-tabview-panel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: auto;
  overflow: hidden;
}

.sms-channel {
  width: 70%;

  .sms-channel-header {
    display: flex;
    align-items: center;
    margin-top: 1px;
    margin-bottom: 13px;
  }
}

.profile-details-avatar {
  width: 32px;
  height: 32px;
}

.sms-thread-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.sms-message-thread {
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  overflow-y: auto;
  padding-right: 4px;
}

.phi-warning {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  padding: 4px 10px;
  align-items: center;
  background-color: #F5F5F5;
  border: 0.001px dashed #696CFF;
  color: #204877;
}

::v-deep .pi {
  margin-right: 6px;
}

.start-message {
  margin: 6px;
  align-self: center;
}

.user-managed {
  width: fit-content;
  background-color: #0073e6BB;
  color: white;
  border-radius: 6px;
  padding: 2px 6px;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.show-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 4px;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  background: #609af8;
}

.sms-submission {
  padding-top: 12px;
  border-top: 1px solid #DEE2E6;
  display: flex;
  flex-direction: column;

  .send-button {
    align-self: flex-end;
    overflow: visible;

    &.disabled {
      opacity: 0.6;
    }

    > :disabled {
      opacity: 0.83;
      color: #FFF !important;
    }
  }

  .dropdown-button {
    top: -88px;
  }
}

.owner-enter-active {
  transition: background-color 1s;
}

.owner-leave-active {
  display: none;
}

.owner-enter,
.owner-leave-to {
  background-color: #99CCFF;
}
</style>